import { Container, Row, Col, Button, Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
import NetworkLogo from "../graphics/NetworkLogo";
import UsMap from "../components/UsMap";
import NetworkLogoHorz from "../graphics/NetworkLogoHorz";
import USMapSmall from "../graphics/USMapSmall";
import ChildIcons from "../graphics/ChildIcons";
import FamilyIcon from "../graphics/FamilyIcon";
// import KidsIconsRow from "../graphics/KidsIconsRow";
import HandIcon from "../graphics/HandIcon";
import HomeHeartIcon from "../graphics/HomeHeartIcon";
import Megaphone from "../graphics/Megaphone";
import Pram from "../graphics/Pram";

import { useAuth } from "../util/AuthContext";

const Home = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <Container fluid className="p-5" style={{ backgroundColor: "#e0e2dd" }}>
        <Container className="w-50">
          <NetworkLogo fill="#000" secondary="var(--color1)" />
        </Container>

        <div>
          <h3>
            We hope to see{" "}
            <span style={{ color: "var(--color1)" }}>
              <strong>20,000</strong>
            </span>{" "}
            children in U.S. foster care being cared for by our Assemblies of
            God churches and families.{" "}
          </h3>

          <div className="px-5">{/* <KidsIconsRow /> */}</div>
        </div>
      </Container>

      <Image fluid src={require("../img/iStock-530822322.jpg")} />

      {/* conference panel */}
      {/* <a href="https://agfostercare.network/conference2023">
<Image
                fluid
                id="Conference"
                src="https://agfostercare.network/images/conference2023.jpg"
                // width="200"
                // height="200"
                target="_blank"
                rel="noopener noreferrer"
                alt="Profile Pic"
                />
                </a> */}

      <Container
        fluid
        className="py-5 text-white"
        style={{ backgroundColor: "var(--color4" }}
      >
        <Row>
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-end"
          >
            <Container className="p-5">
              <USMapSmall color="var(--color6)" />
            </Container>

            <h1 className="display-2" style={{ color: "var(--color1)" }}>
              <strong>400,000</strong>
            </h1>
            <h4>
              children in <strong>foster care</strong>
            </h4>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-end">
            <Container className="p-5">
              <ChildIcons color="var(--color3)" />
            </Container>
            <h1 className="display-2" style={{ color: "var(--color1)" }}>
              <strong>100,000</strong>
            </h1>
            <h4>
              children <strong>ready for adoption</strong>
            </h4>
          </Col>
        </Row>
      </Container>

      <Container fluid className="p-5 bg-color1">
        <Row>
          <Col md={5} className="p-4">
            <Container>
              <NetworkLogo fill="#fff" secondary="var(--color6)" />
            </Container>
          </Col>
          <Col
            md={7}
            className="d-flex flex-column justify-content-center text-white"
          >
            <h2 className="text-center">
              WHO WILL
              <strong> TAKE A STAND</strong>?
            </h2>
            <p>
              We know that every Bible-believing church wants to follow the
              command to care for those that feel orphaned, but often they don’t
              know how or where to start. If the church doesn’t take a stand for
              these children, then who will?
            </p>

            <div className="text-center mb-5">
              {!currentUser && (
                <Button
                  variant="light"
                  className=" w-50"
                  href="/signup"
                  style={{
                    // backgroundColor:"#fff",
                    color: "var(--color1)",
                    border: 0,
                  }}
                >
                  <strong>Join Now</strong>
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className="p-5"
        style={{ backgroundColor: "var(--color5)" }}
      >
        <h2 className="text-center pb-4">
          EVERY CHURCH <strong>DOING SOMETHING</strong>
        </h2>
        <p>
          <strong>AG Foster Care Network</strong> can connect your church
          leadership to ministries that can guide you to help solve the foster
          care crisis in your community. We want to see every church and every
          person doing something.
        </p>

        <p className="text-white text-center h3 py-4">
          <strong>
            We see a day when every Assemblies of God church is doing something
            to help children and families in foster care.
          </strong>
        </p>

        <Container className="col-sm-4">
          <FamilyIcon color="#fff" />
        </Container>
      </Container>

      <UsMap />
      {/* <Container className="p-0">
      </Container> */}

      <Container
        fluid
        style={{ backgroundColor: "var(--color5)" }}
        className="p-5"
      >
        <Container>
          <p className="lead">
            Assemblies of God churches make up 5% of the total evangelical
            churches in the U.S. Therefore we have accepted the responsibility
            to care for 20,000 of the 400,000 children per year. The revival of
            compassion in our fellowship is laying the groundwork to bring
            redemption to broken children and families.
          </p>
          <br />
          <p className="lead">
            We have a beautiful array of rural, urban, and suburban churches
            that are small, medium, and large in size. Not every church can find
            families to foster or adopt children, but every church can do
            something. Here are four areas of involvement:
          </p>
        </Container>
      </Container>

      <Container className="p-5 w-75">
        <Row>
          <Col md={3} className="p-5">
            <Megaphone />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <h2 style={{ color: "var(--color4)" }}>
              <strong>Speak Up</strong>
            </h2>
            <h4>
              Let’s pray for, preach about, teach on, and advocate for children
              and families in foster care and for our government and agency
              partners.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="p-5">
            <Pram />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <h2 style={{ color: "var(--color2)" }}>
              <strong>Donate for Physical Needs</strong>
            </h2>
            <h4>
              Donate items to support foster care families, like - bunk beds,
              clothing, and meals.
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="p-5">
            <HandIcon />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <h2 style={{ color: "var(--color5)" }}>
              <strong>Provide Support Services</strong>
            </h2>
            <h4>
              Donate your time to provide babysitting, transportation, meals, or
              respite
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md={3} className="p-5">
            <HomeHeartIcon />
          </Col>
          <Col className="d-flex flex-column justify-content-center">
            <h2 style={{ color: "var(--color1)" }}>
              <strong>Open Your Heart and Home</strong>
            </h2>
            <h4>Prayerfully consider being a foster or adoptive parent.</h4>
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        className="p-5 text-white"
        style={{ backgroundColor: "var(--color4)" }}
      >
        <Container>
          <h2 className="text-center">
            WE ARE HERE TO
            <strong> SERVE</strong>
          </h2>
          <br />

          <h5>Two of the top issues in foster care are:</h5>
          <ul>
            <li>There are not enough families to foster or adopt</li>
            <li>
              50% of foster families quit after the first year, and another 50%
              quit after the second year.
            </li>
          </ul>

          <p>
            Churches are positioned to solve both of these problems. We have
            families and we have support systems within the church.
          </p>

          <p>
            The AG Foster Care Network is a group of organizations that exist to
            serve and support you, the church.
          </p>
        </Container>
      </Container>

      <Container className="p-5 text-center">
        <Row className="w-md-75 p-5">
          <Col md={4}>
            {/* <Image fluid src="https://www.agfostercare.network/conference2023/wp-content/uploads/2022/07/Dr.-Alan-Bixler.jpg"/> */}
            <Image fluid src={require("../img/bixler.jpg")} />
          </Col>
          <Col className="text-start d-flex flex-column align-self-end">
            <p className="fs-1 fw-bold">Alan Bixler</p>
            <p className="fs-5 lh-1">
              Executive Director AG Foster Care Network & COMPACT Family
              Services
            </p>
          </Col>
        </Row>

        <Container className="w-50">
          <NetworkLogoHorz secondary="var(--color1)" />
        </Container>
        <br />

        <h5>
          <a href="https://ag.org">
            <strong>The General Council of the Assemblies of God</strong>
          </a>
        </h5>

        <h5>1445 N. Boonville Avenue Springfield, MO 65802-1894</h5>

        <br />
        <a href="https://compact.family">
          <small>
            In collaboration with Assemblies of God Family Services Agency, dba
            COMP<strong style={{ fontSize: "1rem" }}>ACT</strong> FAMILY
            SERVICES
          </small>
        </a>
      </Container>

      {/* <Container className="my-5">
      <Container className="col-6">
        <NetworkLogoHorz fill="#000" secondary="var(--color1)" />
      </Container>
</Container> */}
    </>
  );
};

export default Home;
