
import { Container, Row, Col, Button, Card, Image } from "react-bootstrap";


export default function Resources() {
    return(
        <>
        <Container>

<Card className="p-3">
            <Row>
<Col>
    <Image fluid src="https://agfostercare.network/downloads/AGFCN-Youth-&-Kids-PDF-1.webp" alt="" />


</Col>
    <Col>
    <Image fluid src="https://agfostercare.network/downloads/AGFCN-Youth-&-KidsPDF-2.webp" alt="" />
    </Col>
    

            </Row>
        <Button className="mt-3 btn-secondary fw-bold">
            <a href="https://agfostercare.network/downloads/AGFCN-Youth-&-Kids.pdf" download="AGFCN-Youth-&-Kids.pdf" target="_blank">
            Download Printable PDF
            </a>
            </Button>
</Card>

<Card className="p-3">
            <Row>
<Col>
    <Image fluid src="https://agfostercare.network/downloads/AGFCN-Youth-&-Kids-h.webp" alt="" />

        <Button className="mt-3 btn-secondary fw-bold">
            <a href="https://agfostercare.network/downloads/AGFCN-Youth-&-Kids-h.png" download="AGFCN-Youth-&-Kids-h.png" target="_blank">
            Download PNG
            </a>
            </Button>

</Col>
    <Col>
    <Image fluid src="https://agfostercare.network/downloads/AGFCN-Youth-&-Kids2-h.webp" alt="" />
        <Button className="mt-3 btn-secondary fw-bold">
            <a href="https://agfostercare.network/downloads/AGFCN-Youth-&-Kids2-h.png" download="AGFCN-Youth-&-Kids2-h.png" target="_blank">
            Download PNG
            </a>
            </Button>
    </Col>
    

            </Row>
</Card>

        </Container>
        </>
    )
}