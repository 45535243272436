import { useState, useEffect } from "react";
import { Container, Card, Button, Alert, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../util/firebase";
import { useAuth } from "../util/AuthContext";
// import { ReactComponent as ProfilePic } from "../graphics/ProfilePic.svg";

export default function Dashboard() {
  const { currentUser, signout } = useAuth();

  const [error, setError] = useState("");
  const navigate = useNavigate();

  // const {theTest, setTheTest} = useState("")

  // load currentUser's profile pic. replace placement on load
//  getDownloadURL(
//     ref(storage, `/users/${currentUser.uid}.jpg`)
//     ).then((url) =>{
//       const img = document.getElementById('myPic');
//       img.setAttribute('src', url)
//     })
//     .catch((error) => {
//       console.log(error)
//     })


//   useEffect(() => {

// async function GetIt() {
//   const url = ref(storage, `users/${currentUser.uid}.jpg`);
//   const img = document.getElementById("myPic");
//   img.setAttribute("src", url);
// }

// try {
//   GetIt()

//     } catch (error) {
//       console.log(error);
//     }
//   }, []);

  async function handleLogout() {
    setError("");

    try {
      await signout();
      navigate("/signin");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <Container
        className="my-3 d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: "84vh" }}
      >
        <Card style={{ maxWidth: "800px" }}>
          <Card.Body className="text-center mb-4">
          
            {/* <Link to="/update-profile-image"> */}
          
              {/* <div style={{maxWidth:"200px"}}>
           <ProfIcon />
           </div> */}


              <Image
                fluid
                roundedCircle
                id="myPic"
                src="https://agfostercare.network/images/profileBlank.png"
                width="200"
                height="200"
                alt="Profile Pic"
              />
            {/* </Link> */}

            {/* <h2>Dashboard</h2> */}
            {/* <br /> */}

            {/* {loading && <div>Loading...</div>} */}
            {error && <Alert variant="danger">{error}</Alert>}

            {currentUser && (
              <div>
                <h4>{currentUser.email}</h4>
              </div>
            )}
            <br />

            {/* <h5>
            <strong>Email:</strong> {currentUser.email}
          </h5> */}

            <Link to="/update-profile" className="btn btn-primary w-100 mt-3">
              Update Profile
            </Link>
            <Link to="/update-password" className="btn btn-primary w-100 mt-3">
              Update Email or Password
            </Link>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          <Button variant="link" className="link" onClick={handleLogout}>
            Sign Out
          </Button>
          {/* <Button variant="link" onClick={deleteAccount}>
          Delete account from AGFosterCare.network.
        </Button> */}
        </div>
      </Container>
    </>
  );
}

const ProfileIcon = () => {
  return (
    <svg viewBox="0 0 200 200">
      {/* <style>
.brand{
  fill="var(1c)"
}
      </style> */}
      {/* <path fill="#f0f" d="M0 0h200v200H0z"/> */}
      <path
        className="secondary"
        d="M100.1 11a88.8 88.8 0 0 0-61.3 153c3-24 25-48.8 61.3-48.8s58.3 24.8 61.3 48.8A88.8 88.8 0 0 0 100 11Zm0 95.3a40 40 0 1 1 40.1-40 40 40 0 0 1-40 40Z"
      />
    </svg>
  );
};
