export default function NetworkLogo({fill, secondary}) {
    return (
        <>
        
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                height="100%"
                width="100%"
                >
                  
<g fill={fill}>

{/* Foster Care */}
  <path fill={secondary} d="M17.2 644V534.4H77V559H44v18.7h31.8v24.5H44V644zM87.4 591.7c0-33.9 24.4-59.4 57.7-59.4 32 0 58 24.8 58 56.2a56.6 56.6 0 01-56.6 57.6c-30.5 0-59.1-22-59.1-54.4zm87.6-2.4c0-17.1-12.3-32.4-30-32.4-17.4 0-29.7 14.7-29.7 32 0 18 12.3 32.6 30.2 32.6 17.4 0 29.5-14.7 29.5-32.2zM261 566.4c-1.1-7.9-4.4-10.7-9.8-10.7-5.2 0-9.7 3.7-9.7 8.6 0 6.5 6 9.5 18 14.7 24 10.4 30.2 19 30.2 33.5 0 21.1-14.6 33.6-38.3 33.6S212 633.6 212 610v-2.4h27.8c.2 9 4.6 15 11.6 15a10.4 10.4 0 0010.5-10.5c0-8.7-12.1-12.6-22-16.6-18-7.3-26.1-16.7-26.1-31 0-18.7 18-32.3 38.2-32.3a41.7 41.7 0 0119.4 4.6c11.7 6 17.3 14.8 17.5 29.5H261zM316.2 644v-85h-21.7v-24.6h71.1V559h-21.5v85zM376.9 644V534.4h60.8V559h-34v17h33v24.6h-33v18.8h34V644zM507.5 644l-25.3-35.4h-.3V644H455V534.4h35.8c11.4 0 20.1 1.5 26 4a36.2 36.2 0 0120 33.8c0 17.3-10.3 31.5-27.2 34.5l29.8 37.3zm-15.3-54.5c11.1 0 18-5.2 18-15.7 0-9.5-7.2-14.8-17.7-14.8H482v30.5h10.3zM676.9 576a28.6 28.6 0 00-26.5-19.1c-17.5 0-29.3 15-29.3 32 0 17.8 12.1 32.6 29.4 32.6 11.1 0 20.6-6.2 26.4-18.5h29.3a56.5 56.5 0 01-55.4 43 57 57 0 01-57.7-58.4c0-29 25-55.3 56.4-55.3 15.1 0 27.3 4.2 38.2 13.5a51.1 51.1 0 0118.5 30.2zM785.4 644l-5.3-15.8h-38l-5.8 15.8h-30l44-109.6h22L815.8 644zm-24-75.4l-.4-.1-11.3 37.4h23zM879.5 644l-25.3-35.4h-.4V644h-26.7V534.4h35.8c11.4 0 20.1 1.5 26 4a36.2 36.2 0 0120 33.8c0 17.3-10.3 31.5-27.2 34.5l29.8 37.3zm-15.3-54.5c11.1 0 18-5.2 18-15.7 0-9.5-7.2-14.8-17.7-14.8H854v30.5h10.3zM923.7 644V534.4h60.9V559h-34v17h33v24.6h-33v18.8h34V644z"/>

{/* Network */}
  <path d="M236.9 795l-65-96.3h-.3V795h-11V685.5h14.8l61.1 91h.3v-91h11v109.6h-11zM269.4 795V685.6h60v10h-49v39.3h47.4v10h-47.4v40.4h49v9.9h-60zM364.5 795v-99.6h-25.2v-9.9h61.2v10h-25V795zM496.9 795l-24.1-89.8h-.3l-24 89.9h-14.1l-31.2-109.6h11.2l26.8 95.5h.3l26-95.5h10.3l26.2 95.4h.3l26.8-95.4h11.1l-31.4 109.6H497zM608 797c-30 0-57.9-24.3-57.9-56.1a57.6 57.6 0 0157.9-57.3c31.8 0 58 25.4 58 56.7 0 31-26.2 56.7-58 56.7zm-.1-103.5c-26 0-46.8 22-46.8 47.4A47.4 47.4 0 00608 787c26.2 0 47-21.5 47-46.7a47.3 47.3 0 00-47.2-46.9zM741.7 795l-37.2-49.5h10.7c19.4 0 31-10 31-24.9 0-10.4-6.4-25.2-31.6-25.2h-20.9v99.7h-11V685.5h33c28.6 0 41.3 16.9 41.3 35.4 0 17.8-13.3 31.8-32.6 33.3l31 40.9h-13.7zM836 795l-36.6-47.4-14.2 17.4v30h-11V685.6h11v63h.3l50.2-63h14L806.5 739l43.4 56.1H836z"/>





  {/* flame */}
  <path d="M418.2 440.3s-17.2-10.6-17-28.7 20.9-46.3 23-57.3 0-16.8 0-16.8 16.4 14.3 14.3 35.9c-1.4 15.3-15.6 33.9-20 44.8s-.3 22-.3 22M433.1 459.7s-10.8-6.7-10.7-18 13.1-29.2 14.5-36 0-10.7 0-10.7 10.3 9 9 22.6c-1 9.7-9.8 21.3-12.6 28.3s-.2 13.8-.2 13.8"/>

{/*  AG  */}
  <path d="M748.2 305.6h-99.9l34.4 35H709v82.2a112 112 0 01-30.5 10.8 183.1 183.1 0 01-38.3 3.7q-26.3 0-47.5-9.1a102.5 102.5 0 01-35.9-25.2 112.7 112.7 0 01-22.5-37.7 133.7 133.7 0 01-7.9-46.3 130.8 130.8 0 018-45.6 115.6 115.6 0 0122.4-37.8 104.4 104.4 0 0135.5-25.6q20.8-9.4 46.6-9.4 25.4 0 46.1 8.8t34 23.3l28.8-30q-18.4-17.5-46-28t-63.2-10.4q-33.8 0-62.6 11.3a149.9 149.9 0 00-49.8 31.5 142.6 142.6 0 00-32.9 48.7l-3 8.4-39.7-92.4H413L281.7 474.5h45.8l33-80 14.7-36.3 55.4-138.4 55.2 138.4s14 36.8 20.1 46.8a132.4 132.4 0 0019.8 25.9A145.9 145.9 0 00573 462l2.5 1q29 11.5 63.5 11.5a255.3 255.3 0 0060-6.7 214.1 214.1 0 0050.9-19.2v-143zM775.6 200.3a7.5 7.5 0 003-.6 2.3 2.3 0 001.3-2.4 2.1 2.1 0 00-1.2-2.1 6.7 6.7 0 00-2.7-.5h-3.2v5.6h2.8zm-6.4-8.6h7a12 12 0 012.7.3 7.3 7.3 0 012.3 1 5.2 5.2 0 011.7 1.7 5.4 5.4 0 01.6 2.7 5 5 0 01-1.2 3.5 6.2 6.2 0 01-3.4 1.8l5.4 8.7h-4.4l-4.6-8.3h-2.5v8.3h-3.5zm-7.9 9.9a14.3 14.3 0 001.2 5.7 15.4 15.4 0 003.1 4.7 14.7 14.7 0 0021 0 15.4 15.4 0 003.1-4.7 14.3 14.3 0 001.1-5.7 14.5 14.5 0 00-1.1-5.8 14.7 14.7 0 00-7.9-7.8 15 15 0 00-11.5 0 14.6 14.6 0 00-7.8 7.8 14.5 14.5 0 00-1.2 5.8m-2.8 0a16.8 16.8 0 011.4-6.8 17.4 17.4 0 013.8-5.6 18.2 18.2 0 015.6-3.7 17.4 17.4 0 0113.6 0 18.2 18.2 0 015.6 3.7 17.6 17.6 0 013.8 5.6 17 17 0 011.4 6.8 16.5 16.5 0 01-1.4 6.7 17.4 17.4 0 01-9.4 9.2 17.5 17.5 0 01-6.8 1.4 17.3 17.3 0 01-6.8-1.4 17.4 17.4 0 01-9.4-9.2 16.5 16.5 0 01-1.3-6.7"/>
  </g>


</svg>
        </>
    )
}
