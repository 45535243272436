export default function HandIcon() {
    return (
        <>

         <svg 
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
  <path d="M321.6 761.2a202.3 202.3 0 01-83.8-18.3c-92.3-38-157-74.6-171.1-187.8a259 259 0 013.5-73.9c5.8-47.7 14.7-111.8 11.3-147-6.7-67.9-27.6-171.8 8-207.2a52.8 52.8 0 0142.8-16.8c41.1-2 56.2 75.8 71.3 126 3.8-23.8 8-45.7 13.2-67.1 18-80.1 29-128.5 81-129.6 64.4 3.8 61.5 67.2 55.2 138.3 28.8-73.4 65.3-154 127.5-134.8a55.9 55.9 0 0134.3 29.8c18.2 31.9 4 108.8-30.5 193 58-71.3 99.6-120.7 144.2-95.7 59 38 20 122.6-33.8 212.4-16 25.7-37.2 59-50.8 82.6 34-9.2 67-16.2 96.9-11.8 76.9 11.9 91.7 52.7 93.6 76 4 42-38.4 52.9-75.7 62.5-31.6 8.2-70.9 18.4-104.6 44a2165.7 2165.7 0 01-91 66.8 201.2 201.2 0 01-141.5 58.6zM126.8 158.4c-18.8 4.6-.6 125.5 2.4 171 4 41-5 106.6-11.7 159.6a217.6 217.6 0 00-3.2 60.6c11.7 96.2 68.7 117 143.4 149.7a153 153 0 00173-32.1c25.8-18.8 54.5-38.6 94.3-69.4 41.4-31.6 87.8-43.6 121.7-52.3 14.4-3.8 33.9-8.8 39.8-13-3-21-34.3-28.8-53-31.7-25.6-3.7-61.7 6.3-88 13.6-10 3.3-33.3 10.7-49.3-5-12.4-11-13-36-2-53.8 10.8-20.1 29.3-49.6 45.6-75.7 41.9-62.6 85.2-152.9 65.5-167.8-28.3 5.2-96.2 104-127.8 136.1-19.7 23.6-51 24.1-62.8-3.2-6.1-15.2-1.3-32.3 3.8-44a1428 1428 0 0024.6-61.6C483.2 131.8 479.5 96.7 466.3 89c-25-7.4-61.7 88.6-75.7 124.8-9.5 24.5-19 42-28.7 66.2C357 293 350 308 342.8 310c-35.8 10-44.8-13.9-46.3-26-1.8-15.9.8-42 3.2-61.2 5-47.8 17.5-130.8-.9-135.4-13.2.3-20.2 25.5-35.2 92.2-3.6 16-7.5 31.4-10 49.4-12.3 66.3-27.6 99-39.8 101.3-11 2.1-33.9-15.8-47.2-53.2-12.9-32.6-32.6-120.1-39.8-118.7zm407.5 325.4zM344 278.8a3.3 3.3 0 010 .3 3.3 3.3 0 000-.3zM130.3 158.1z" fill="#f0db8d"/>
</svg>


        </>
    )
}
