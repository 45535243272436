export default function ChildIcons() {
    return (
        <>

<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 296"
height="100%"
width="100%">

  <path d="M88 205.4v41a17.1 17.1 0 01-.8 6l-.5 1.1c-3.6 7.6-13 9-18.4 3.2a12.6 12.6 0 01-3.4-8v-43.3H41.1l18.5-62c4.3-12-4-29.7-7.5-37.1L28.5 54.9a13 13 0 01-1.3-3.5 11.1 11.1 0 01-.2-2 9.7 9.7 0 014.2-8 10.7 10.7 0 012-1 8.6 8.6 0 015.8 0 9.2 9.2 0 013.7 2.5 9.8 9.8 0 011.5 2.3l.7 1.5L56 70.8c5.4 11.7 10.4 23 20.7 23.2h31.5c10.3-.2 15.3-11.5 20.7-23.2l11.1-24 .7-1.5a9.3 9.3 0 011.5-2.4 9.1 9.1 0 013.7-2.5 8.6 8.6 0 015.9 0 10 10 0 012 1 10 10 0 014 10.1 14 14 0 01-1.3 3.5l-23.7 51.4c-3.4 7.4-11.8 25.2-7.4 37.1l18.5 62H120V249a12.6 12.6 0 01-3.3 7.9 11.2 11.2 0 01-18.5-3.1 13 13 0 01-.5-1.1 17.3 17.3 0 01-.7-6.2v-40.9z" fill="#f0db8d"/>
  <path d="M93.2 37.8A23.2 23.2 0 10116.6 61a23.3 23.3 0 00-23.4-23.2M673.4 120.4a136 136 0 00-6.2-15.5l-23.7-51.4a13.5 13.5 0 01-1.2-3.5 10.2 10.2 0 01-.2-2 9.7 9.7 0 014.2-8 10.5 10.5 0 012-1 8.6 8.6 0 015.8 0 9 9 0 013.7 2.4 9.2 9.2 0 011.5 2.4l.7 1.5 11 24c5.5 11.7 10.5 23 20.8 23.1h31.5c10.3-.1 15.3-11.4 20.7-23.1l11-24 .8-1.5a9.3 9.3 0 011.5-2.4A8.9 8.9 0 01761 39a8.6 8.6 0 015.8 0 10.6 10.6 0 012 1 9.7 9.7 0 014.2 8 9.5 9.5 0 01-.2 2 13.5 13.5 0 01-1.2 3.6L747.9 105a127.4 127.4 0 00-6.2 15.5 61 61 0 00-2.6 18.8V248a13.3 13.3 0 01-4 8.4 13.5 13.5 0 01-21.4-3.4 12.2 12.2 0 01-1-2.7 12.5 12.5 0 01-.5-2v-67.4c0-4.7-6.2-5.5-8.5-2.4a1.4 1.4 0 00-.2.3 4.2 4.2 0 00-.6 2.1v66.5a13.3 13.3 0 01-7.5 11.5 13.6 13.6 0 01-15.4-2.5 13.2 13.2 0 01-4-9.2V139a60.4 60.4 0 00-2.6-18.7" fill="#f0db8d"/>
  <path d="M708.3 36.4a23.2 23.2 0 1023.4 23.2 23.3 23.3 0 00-23.4-23.2M530.4 90.7c7.7.3 13.9 4.1 19.1 10l2.2 2.9 33.7 45a16 16 0 011.4 1.9 9 9 0 01-4.3 12.4 7.9 7.9 0 01-1.8.6 9 9 0 01-8.1-2.5 10 10 0 01-.9-1l-20.3-27-4-5.4c-2.2-2.6-7.5-1-6 4 1 2.8 2.5 8.2 3.6 12.1l18.6 60.7h-24.3v44.2a12.9 12.9 0 01-3.4 8c-5.6 5.9-15.2 4.5-18.9-3.2a10.8 10.8 0 01-.4-1 17.4 17.4 0 01-.9-6.4v-41.6h-9V246a17.4 17.4 0 01-.8 6.2 10.7 10.7 0 01-.4 1.1c-3.7 7.7-13.3 9.1-18.9 3.2a12.8 12.8 0 01-3.4-8v-44.2h-24.3l18.6-60.8c1-3.8 2.6-9.2 3.5-12 1.6-5.1-3.7-6.6-5.8-4l-4.1 5.3-20.3 27a7.1 7.1 0 01-.9 1 9 9 0 01-8 2.5 8 8 0 01-2-.5 9 9 0 01-4.2-12.5 17.2 17.2 0 011.3-2l33.7-45 2.3-2.7c5.2-6 11.4-9.8 19.1-10zM511.2 36.5a23.7 23.7 0 1023.9 23.7 23.8 23.8 0 00-23.9-23.7M296.7 37.8a23.7 23.7 0 1023.8 23.7 23.8 23.8 0 00-23.8-23.7M328.2 135.8V248a13.2 13.2 0 01-3.9 8.4 13.5 13.5 0 01-21.5-3.3 11.2 11.2 0 01-1-2.7 12.3 12.3 0 01-.4-2v-67.4c0-4.7-6.3-5.5-8.5-2.4a.9.9 0 00-.3.3 4.2 4.2 0 00-.6 2.1v66.5a13.3 13.3 0 01-7.5 11.5 13.6 13.6 0 01-15.4-2.6 13.2 13.2 0 01-4-9.1V135.9v-5.3c-.4-3.2-4.5-4-6.4-1.7l-4.3 5.3-21.4 27a11.6 11.6 0 01-.8 1 9.9 9.9 0 01-8.6 2.5 9.5 9.5 0 01-1.9-.6 8.7 8.7 0 01-4.5-12.4 16 16 0 011.4-2l35.4-44.9 2.4-2.8a28 28 0 0120.1-10h40.3c8 .2 14.7 4 20.1 10a55 55 0 012.4 2.8l35.4 45a18.7 18.7 0 011.4 1.9 8.7 8.7 0 01-4.5 12.4 9.5 9.5 0 01-2 .6 9.8 9.8 0 01-8.4-2.5 10.3 10.3 0 01-1-1l-21.3-27-4.3-5.3c-1.8-2.2-6-1.5-6.3 1.7-.2 1.6 0 3.5 0 5.2" fill="#f0db8d"/>
</svg>

        </>
    )
}
