export default function Pram() {
    return (
        <>

<svg
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 800 800"

>
  <path d="M724 204c-49 .9-93.8 44-100.3 85.5H354.3c-4.7-11.4 12.8-265.1-12.6-260.6-40 2.9-87.3 30.6-164.3 27.8a22 22 0 00-20.6 15.2C142.5 134 56.2 147.1 57.4 192.2c16.7 44.4-2.5 111.5-2.7 112.2-15.2 102.7 47.6 251 221.8 232.4l37.6 19.8-23.5 12.4c-71-54.6-184 1.4-181.1 92.1 9.4 192.1 303.5 110.4 212-58.7l39.8-21 39.7 21c-91.5 169.1 202.6 250.8 212 58.7 2.8-90.7-110.1-146.8-181.1-92.1l-23.5-12.4 37.8-20c164.7 19.8 232.1-130 218.5-225.3-5.6-14.2 28.7-69.3 63.3-63.5 28.2-2.2 24.7-46.5-4-43.8zM223.8 730.9c-91.2 1.3-91.2-140.9 0-139.6 91.2-1.3 91.1 140.9 0 139.6zm275-139.6c91.3-1.3 91.3 140.9 0 139.6-91 1.3-91-140.9 0-139.6zM180.2 121.8C197.8 134 257.8 276 291 248.6c38.3-19.8-69.7-130-73.9-150.4 28-2.3 64.6-7 93.3-15.5V275l-189.2-93.7c18.8-14.3 42-35 58.9-59.6zm-72.4 102l130.8 64.7H103.2c3-17.3 5.8-40.7 4.5-64.7zm444.2 251.9a213 213 0 01-54 13.7l-12.3-23.1c-36 27-81.7 30-126.4 26.5-42.8 4-87.8-.8-122.3-26.5l-12.4 23.5C137 494.3 93.3 396.6 98 333.4h522.6c-1.6 38.5-11.7 119.2-68.7 142.3z" fill="#73c0ba"/>
  <path d="M223.7 625.4c-46.3-.7-46.3 72 0 71.4 46.4.6 46.3-72 0-71.4zm0 43.9c-10.6.1-10.6-16.6 0-16.5 10.6-.1 10.7 16.7 0 16.5zM498.8 696.8c46.4.6 46.3-72 0-71.4-46.3-.7-46.4 72 0 71.4zm0-44c10.6-.1 10.6 16.7 0 16.5-10.7.2-10.7-16.7 0-16.5z" fill="#73c0ba"/>
</svg>

        </>
    )
}
