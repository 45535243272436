import { db } from "../util/firebase";
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { useState, useEffect } from "react";

import { BsCheckCircleFill } from "react-icons/bs";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import { useAuth } from "../util/AuthContext";

import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

export default function DirectoryPage() {
  const { currentUser } = useAuth();

  // const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  // FETCH PROFILES CODE ON LOAD //

  useEffect(() => {
    // setLoading(true);
    async function GetUsers() {
      const users = [];
      const q = query(
        collection(db, "Users"),
        where("ag_affiliated", "==", "Yes")
      );
      const usersSnapshot = await getDocs(q);
      usersSnapshot.forEach((doc) => {
        users.push({
          ...doc.data(),
          key: doc.id,
        });
        const tempProf = [];
        const profData = doc.data();
        if (currentUser) {
          if (profData.uid === currentUser.uid) {
            tempProf.push({ ...doc.data(), key: doc.id });
            // setUserFirstName(tempProf[0].first_name);
            // setUserLastName(tempProf[0].last_name);
            // setIsProfile(true);
            if (tempProf[0].isAdmin === true) {
              setIsAdmin(true);
            }
          }
        }
      });
      setProfiles(users);
    }
    try {
      if (profiles < 1) {
        console.log("loading");
        GetUsers();
      }
    } catch (error) {
      setError("Trouble gathering profiles. Please try again later.");
    }
  }, []); // eslint-disable-line

  // try add .trim*()
  // query ---------------------
  const [q, setQ] = useState("");

  const filterData = profiles.filter(
    (a) =>
      a.last_name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      a.first_name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      a.city.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      // a.org_ag_title.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      // a.org_ag.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      // a.church.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      // a.org_ag_url.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      // a.social_media.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      a.state.toUpperCase().indexOf(q.toUpperCase()) > -1
  );
  // const filterData = profiles.filter(a => {
  //   return a.last_name.toUpperCase().includes(q.toUpperCase())
  // });

  const [filterBy, setFilterBy] = useState("");
  const [filterState, setFilterState] = useState("AL");

  return (
    <>
      <Container className="py-4" style={{ minHeight: "88vh" }}>
        {error && <Alert variant="warning">{error}</Alert>}

        <h1 className="text-center">Network Directory</h1>
        {isAdmin && profiles && <h5>{profiles.length} Members</h5>}
        {error && <Alert variant="warning">{error}</Alert>}

        {filterBy ? (
          <>
            <em>Showing by {filterBy}:</em>
            {filterState !== "Select State" && filterBy === "State" && (
              <strong> {filterState}</strong>
            )}
          </>
        ) : (
          <>
            <br />
          </>
        )}
        <div>
          <Form.Control
            type="search"
            name="search-form"
            id="search-form"
            placeholder="search..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
        </div>
        <div className="d-flex">
          {/* <h5 className="mt-3">Show:</h5> */}
          <span className="me-3 mt-3 fw-bold small">
Filter by:
          </span>
          <Button
            size="sm"
            className="mt-2 me-1"
            onClick={() => setFilterBy("Foster Parent")}
          >
            Foster Parent
          </Button>

          <Button
            size="sm"
            className="mt-2 me-1"
            onClick={() => setFilterBy("Credentialed")}
          >
            AG Credentialed
          </Button>

          <Button
            size="sm"
            className="mt-2 me-1"
            onClick={() => setFilterBy("State")}
          >
            State
          </Button>

          {filterBy && (
            <Button
              size="sm"
              className="mt-2 btn me-1"
              variant="warning"
              onClick={() => setFilterBy("")}
            >
              Show all
            </Button>
          )}
        </div>
        <div>
          {filterBy === "State" && (
            <Form.Control
              className="mt-2"
              required
              id="state"
              as="select"
              onChange={(e) => setFilterState(e.target.value)}
              value={filterState}
            >
              {/* <option value={null}>Select State</option> */}
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </Form.Control>
          )}
        </div>

        {profiles &&
          filterBy === "" &&
          filterData.map((pr, id) => <ProfileCard {...pr} />)}

        {filterBy === "Foster Parent" &&
          filterData.map((pr, id) => {
            return pr.fosterParent === "Yes" ? <ProfileCard {...pr} /> : null;
          })}

        {filterBy === "Credentialed" &&
          filterData.map((pr, id) => {
            return pr.ag_credentialed === "Yes" ? (
              <ProfileCard {...pr} />
            ) : null;
          })}

        {filterBy === "State" &&
          filterData.map((pr) => {
            return pr.state === filterState ? <ProfileCard {...pr} /> : null;
          })}

        {/* end map profiles */}
      </Container>
    </>
  );
}

function ProfileCard({
  uid,
  first_name,
  last_name,
  city,
  state,
  fosterParent,
  org_ag,
  org_ag_title,
  ag_credentialed,
  email,
  org_other,
  org_other_title,
  org_ag_url,
  org_other_url,
  comments,
}) {
  // -----------------
  // begin user mail form in profile
  function MailUserForm({ emailTo, first_name }) {
    const { currentUser } = useAuth();

    const [msg, setMsg] = useState("");
    const [msgSent, setMsgSent] = useState(false);

    async function SendIt(e) {
      e.preventDefault();
      await addDoc(collection(db, "mail"), {
        to: `${emailTo}`,
        replyTo: `${currentUser.email}`,
        // from:`${currentUser.email}`,
        message: {
          subject: "Message from AG Foster Care Network Member",
          text: `${currentUser.email} is reaching out to you via the AG Foster Care Network. This is what they had to say: ${msg}
              `,
          html: `${currentUser.email} is reaching out to you via the AG Foster Care Network. This is what they had to say: ${msg}
              `,
        },
      });

      setMsgSent(true);
      setMsg("");
    }

    return (
      <Form
        onSubmit={SendIt}
        className="text-white p-4"
      >
        <p>
          <strong>You can email {first_name} here:</strong>
        </p>

        <Form.Control
          as="textarea"
          value={msg}
          onInput={(e) => setMsg(e.target.value)}
          type="text"
        />

        {msgSent === false && (
          <Button type="submit" className="mt-2">
            Send email
          </Button>
        )}

        {msgSent === true && (
          <Button disabled type="submit" className="mt-2">
            Email sent!
          </Button>
        )}
      </Form>
    );
  }
  // end send email form in profile
  // ---------------------------------

  const [moreToggle, setMoreToggle] = useState(false);

  return (
    <>
      {/* wrapper */}
      <Container fluid key={uid} className="position-relative pt-3 mt-4">
        {/* ---------cickable toggle button------------ */}
        <div
          className="w-100 position-absolute"
          style={{ minHeight: "110px", top: "0", left: "0", zIndex: "2" }}
          onClick={() => setMoreToggle(!moreToggle)}
        ></div>

        {/* -------------PROFILE PHOTO LARGE----------- */}

        <div
          className="position-absolute d-none d-md-block"
          style={{ zIndex: "1", left: "-0.15em", top: "1.1em" }}
        >
          <ProfilePic />
        </div>
        {/* ------------PROFILE PHOTO SM--------------- */}
        <div
          className="position-absolute d-md-none"
          style={{
            zIndex: "1",
            left: "50%",
            transform: "translate(-50%, -30%)",
          }}
        >
          <ProfilePic />
        </div>

        {/* --------badges START - LARGE-------------- */}

        <div
          className=" d-none 
          d-md-flex
          position-absolute m-0 d-flex text-white"
          style={{ right: "5rem", top: ".1em", zIndex: "-1" }}
        >
          {/* --------------badge FP---------------  */}
          {fosterParent === "Yes" && (
            <div
              className=" text-uppercase pt-1 px-2 rounded d-flex"
              style={{
                backgroundColor: "var(--color4)",
                fontSize: ".7rem",
                marginRight: ".5rem",
              }}
            >
              <p>
                <BsCheckCircleFill />
              </p>
              <p className="ps-1">Foster Parent</p>
            </div>
          )}

          {/* --------------badge AG CRED---------------- */}
          {ag_credentialed === "Yes" && (
            <div
              className="text-uppercase pt-1 px-2 rounded d-flex"
              style={{
                backgroundColor: "var(--color1)",
                fontSize: ".7rem",
                marginRight: ".5rem",
              }}
            >
              <p>
                <BsCheckCircleFill />
              </p>
              <p className="ps-1">AG Credentialed</p>
            </div>
          )}
        </div>

        {/* ---------badges BELOW LARGE-------------- */}

        {/* ---------badge FosterParent----------  */}
        {fosterParent === "Yes" && (
          <div
            className="text-uppercase d-md-flex
            d-md-none position-absolute rounded text-white"
            style={{
              backgroundColor: "var(--color4)",
              fontSize: ".7rem",
              width: "45%",
              zIndex: "-1",
              top: ".1rem",
              marginLeft: "2em",
              paddingLeft: ".5em",
              paddingTop: ".25em",
            }}
          >
            <div className="d-flex">
              <p>
                <BsCheckCircleFill />
              </p>
              <p className="ps-1" style={{ paddingTop: ".1em" }}>
                Foster Parent
              </p>
            </div>
          </div>
        )}

        {/* ------------badge AG CRED------------- */}
        {ag_credentialed === "Yes" && (
          <div
            className="text-uppercase d-md-flex 
            d-md-none position-absolute rounded text-white"
            style={{
              backgroundColor: "var(--color1)",
              fontSize: ".7rem",
              width: "45%",
              right: "1em",
              zIndex: "-1",
              top: ".1rem",
              marginRight: "2em",
              paddingLeft: ".5em",
              paddingTop: ".25em",
            }}
          >
            <div className="d-flex justify-content-end pe-2">
              <p>
                <BsCheckCircleFill />
              </p>
              <p className="ps-1" style={{ paddingTop: ".1em" }}>
                AG Credentialed
              </p>
            </div>
          </div>
        )}

{/* -------------MAIN CARD-------------- */}

        <Container
          fluid
          style={{
            borderRadius: "25px",
            marginTop: "10px",
            paddingTop: "13px",
            marginBottom: "10px",
            backgroundColor: "var(--color3)",
          }}
        >
          {/* ------------NAME CITY BLOCK LARGE-------------- */}

          <div
            className="
      flex-sm-column 
      flex-md-row 
      align-items-center 
      px-3 
      pt-sm-5 
      pt-md-1 
      d-none
      d-md-flex"
          >
            <p
              className="fw-bold text-capitalize bd-highlight ps-md-5 text-white"
              style={{ fontSize: "1.3rem" }}
            >
              {first_name} {last_name}
            </p>
            <p
              className="text-uppercase text-md-end text-sm-center ms-md-auto bd-highlight ls-1"
              style={{ color: "var(--color2)", fontSize: ".85rem" }}
            >
              {city && <>{city} </>}
              {state && state !== "NONE" && state}
            </p>
            </div>


{/* ------NAME & CITY BLOCK LESS THAN LARGE--------- */}
            <div
              className="
          pb-3
          d-sm-block
          d-md-none
          flex-column
          align-items-center 
          px-3 
            "
            >
              <p
                className="fw-bold text-capitalize ps-md-5 text-white text-center"
                style={{
                  fontSize: "1.7rem",
                  lineHeight: "1.1em",
                  paddingTop: "1.2em",
                }}
              >
                {first_name}
                <br />
                {last_name}
              </p>

              <p
                className="text-uppercase text-center ls-1"
                style={{
                  color: "var(--color2)",
                  fontSize: ".85rem",
                  lineHeight: "1em",
                }}
              >
                {city && <>{city} </>}
                {state && state !== "NONE" && state}
              </p>
            </div>

          {/* only show body if items exists */}
          {moreToggle &&
            (comments ||
              org_ag ||
              org_other ||
              email ||
              org_ag_title ||
              org_other_title ||
              org_other_url ||
              org_ag_url) && (
              <>
             
                  {comments && (
                    <div className="px-0 pt-3 position-relative">
                      <div
                        className="position-absolute start-0 text-primary"
                        style={{ fontSize: "1.75rem" }}
                      >
                        <FaQuoteLeft />
                      </div>
                      <p className="p-5 text-white text-start">{comments}</p>
                      <div
                        className="position-absolute end-0 text-primary"
                        style={{ fontSize: "1.75rem", bottom: "1rem" }}
                      >
                        <FaQuoteRight />
                      </div>
                    </div>
                  )}

                  {(org_ag || org_other) && (
                    <Row className="d-flex justify-content-center mt-3 pb-3">
                      {org_ag && (
                        <Col className="p-1 text-center d-flex align-items-stretch" lg={6}>
                          <div
                            className="w-100 p-4 rounded d-flex flex-column justify-content-center align-items-center"
                            style={{ backgroundColor: "#D0EAE8" }}
                          >
                            {org_ag && (
                              <p className="fs-4 fw-bold lh-1 text-uppercase">
                                {org_ag}
                              </p>
                            )}

                            {org_ag_title && (
                              <p
                                className="fs-5 text-capitalize"
                                style={{ lineHeight: "1.2rem" }}
                              >
                                {org_ag_title}
                              </p>
                            )}
                            {org_ag_title && (
                              <a
                                rel="noopener noreferrer"
                                target="_blank"
                                className="text-decoration-none fw-bold"
                                href={`//${org_ag_url.replace(
                                  /^\/\/|^.*?:(\/\/)?/,
                                  ""
                                )}`}
                              >
                                {org_ag_url.replace(/^\/\/|^.*?:(\/\/)?/, "")}
                              </a>
                            )}
                          </div>
                        </Col>
                      )}

                      {org_other && (
                        <Col className="p-1 text-center d-flex align-items-stretch" lg={6}>
                          <div
                            className="w-100 p-4 rounded d-flex flex-column justify-content-center align-items-center"
                            style={{ backgroundColor: "#F8EEC6" }}
                          >
                            <p className="fs-4 fw-bold lh-1 text-uppercase">
                              {org_other && org_other}
                            </p>
                            {org_other_title && (
                              <p
                                className="fs-5 text-capitalize"
                                style={{ lineHeight: "1.2rem" }}
                              >
                                {org_other_title}
                              </p>
                            )}

                            {org_other_title && (
                              <a
                                className="text-decoration-none fw-bold"
                                rel="noopener noreferrer"
                                target="_blank"
                                href={`//${org_other_url.replace(
                                  /^\/\/|^.*?:(\/\/)?/,
                                  ""
                                )}`}
                              >
                                {org_other_url.replace(
                                  /^\/\/|^.*?:(\/\/)?/,
                                  ""
                                )}
                              </a>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}

                  {email && (
                    <div className="my-3">
                      <MailUserForm emailTo={email} first_name={first_name} />
                    </div>
                  )}

              </>
            )}
          {/* end show body if items exists */}
        </Container>
      </Container>
    </>
  );
}

const ProfilePic = () => {
  return (
    <svg viewBox="0 0 200 200" width="79">
      <style></style>
      <circle cx="50%" cy="50%" r="96" fill="#fff" />

      <path
        d="M100.1 11a88.8 88.8 0 0 0-61.3 153c3-24 25-48.8 61.3-48.8s58.3 24.8 61.3 48.8A88.8 88.8 0 0 0 100 11Zm0 95.3a40 40 0 1 1 40.1-40 40 40 0 0 1-40 40Z"
        fill="#E5A26B"
      />
    </svg>
  );
};
