import {
  collection,
  getDocs,
  doc,
  addDoc,
  onSnapshot,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Form,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { useAuth } from "../util/AuthContext";
import { AiOutlineArrowLeft } from "react-icons/ai";
// import { Helmet } from "react-helmet";

import { db } from "../util/firebase";

export default function PostPage() {
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [posts, setPosts] = useState([]);
  const [responses, setResponses] = useState([]);
  const [date] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState("");
  const [response, setResponse] = useState([]);
  const [postTitle, setPostTitle] = useState("");
  const [category, setCategory] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [UserLastName, setUserLastName] = useState("");
  const [isProfile, setIsProfile] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const [profiles, setProfiles] = useState([]);

  const [onePostId, setOnePostId] = useState("");
  function clearOnePostId() {
    setOnePostId("");
  }



  // load all posts and set profile
  //eslint-disable-next-line
  useEffect(() => {
    setLoading(true);

    // async function fetchProfiles() {
    //   const userRef = doc(db, "Users", currentUser.uid)
    //   const userSnap = await getDoc(userRef)
    // if (userSnap.exists()){
    //   console.log('Loading profile')
    //   console.log(userSnap.data().first_name)
    //    setUserFirstName(userSnap.data().first_name)
    //    setUserLastName(userSnap.data().last_name)
    //    setIsProfile(true)
    //     if (userSnap.data().isAdmin === true) {
    //       setIsAdmin(true)
    //       console.log('is admin')
    //     }
    // }    else {
    //   console.log('no profile found')
    // }
    // }

    async function fetchProfiles() {
      console.log("Loading profile");

      const usersRef = collection(db, "Users");
      const usersSnap = await getDocs(usersRef);
      const tempProfs = [];
      const tempProf = [];
      usersSnap.forEach((doc) => {
        tempProfs.push({
          ...doc.data(),
          key: doc.data(),
        });
        // now push current user first last name
        setProfiles(tempProfs);
        const profData = doc.data();
        if (currentUser) {
          if (profData.uid === currentUser.uid) {
            tempProf.push({ ...doc.data(), key: doc.id });
            setUserFirstName(tempProf[0].first_name);
            setUserLastName(tempProf[0].last_name);
            setIsProfile(true);
            if (tempProf[0].isAdmin === true) {
              setIsAdmin(true);
            }
          }
        }
      });
    }

    // regular fetch... not snapshot
    //  ------------------------------------
    //     async function getMessages() {
    //         const msgz = []
    //         const msgSnapshot = await getDocs(collection(db, 'Contact'));
    //         msgSnapshot.forEach(doc => {
    //             msgz.push({
    //                 ...doc.data(),
    //                 key: doc.id
    //             })

    //         });
    //         setAllMsgs(msgz)
    // }
    //  getMessages()

    async function fetchPosts() {
      const postRef = collection(db, "Posts");
      onSnapshot(postRef, (snapshot) => {
        setPosts(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });
    }
    async function fetchResponses() {
      const postRef = collection(db, "PostResponses");
      onSnapshot(postRef, (snapshot) => {
        setResponses(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      });
    }

    try {
      fetchPosts();
      fetchProfiles();
      fetchResponses();
      setLoading(false);
    } catch (e) {
      setError(e);
    }
  }, []); // eslint-disable-line

  const handlePost = async (event) => {
    event.preventDefault();
    await addDoc(collection(db, "Posts"), {
      poster_uid: currentUser.uid,
      poster_first_name: userFirstName,
      poster_last_name: UserLastName,
      poster_email: currentUser.email,
      post_date: date,
      post: post,
      post_title: postTitle,
      post_category: category,
    });
// send email to Ted Stackpole when someone makes original post
    await addDoc(collection(db, "mail"), {
      form:"new post alert",
      date: date,
      // to: `${emailTo}`,
      to: "tstackpole@agfsa.org",
      replyTo:`james@mediavelocity.com`,
      // replyTo:`${email}`,
      message: {
        subject: `AGFCN New post alert: ${postTitle} in ${category}, by ${userFirstName} ${UserLastName}`,
        text: `Greetings Ted! ${userFirstName} ${UserLastName} just posted ${postTitle} in the ${category} category at AG Foster Care Network: ${post}`,
        html: `Greetings Ted! ${userFirstName} ${UserLastName} just posted ${postTitle} in the ${category} category at AG Foster Care Network: ${post}`,
      }
    })

    setPost("");
    setPostTitle("");
  };

  const handleResponsePost = async (event) => {
    event.preventDefault();

    await addDoc(collection(db, "PostResponses"), {
      poster_uid: currentUser.uid,
      original_post_id: onePostId,
      poster_first_name: userFirstName,
      poster_last_name: UserLastName,
      poster_email: currentUser.email,
      post_date: date,
      post: response,
    });
    setResponse("");
  };

  // -------------------
  // -------------------
  // response card
  function Response({
    id,
    poster_uid,
    post_date,
    post,
    currrentUser,
    isAdmin,
    original_post_id,
    poster_first_name,
    poster_last_name,
  }) {
    const deleteResponse = async (e) => {
      e.preventDefault();
      await deleteDoc(doc(db, "PostResponses", id));
    };

    const [postEditor, setPostEditor] = useState(false)
    const [postUpdate, setPostUpdate] = useState(post)

    // udpate post on fb
    const updatePost = async (e) => {
      e.preventDefault();
      const updatePostRef = doc(db, "PostResponses", id)
      await updateDoc(updatePostRef, {
    post: postUpdate,
    poster_uid: currentUser.uid,
    poster_first_name: userFirstName,
    poster_last_name: UserLastName,
    poster_email: currentUser.email,
      })
      setPostEditor(false)
    }

    return (
      <div key={id} className="d-flex flex-column align-items-center">
        <Card bg="light" className="w-100 p-3 my-2" key={id}>
          <div className="d-flex justify-content-start">
            {/* get name from profiles in case of name change later */}
            {profiles &&
              profiles.map((p) => {
                return p.uid === poster_uid ? (
                  <div key={p.uid} className="pe-2">
                    <strong>
                      {p.first_name} {p.last_name}
                    </strong>
                  </div>
                ) : null;
              })}

            <p className="p-0"> {post_date.toDate().toDateString()}</p>
          </div>


      {/* SHOW AND EDIT POST AREA */}
      {postEditor ?
          <>
<Form className="mb-3">
  <Form.Control defaultValue={post} 
  rows="7"
  as="textarea"
  id="post"
  onChange={(e) => setPostUpdate(e.target.value)}
  />
</Form>
          </>  
          :
          <>
          <p className="my-3">
          {post}
          </p>
          </>  
          }

          {currentUser && (poster_uid === currentUser.uid || isAdmin === true) ? (
            <div>
              {!postEditor &&
                    <Button
                    className="btn-sm"
                    variant="warning"
                    onClick={() => setPostEditor(!postEditor)}
                    >
                      Edit Post
                    </Button>
                      }
                   {postEditor &&
                    <Button
                    className="btn-sm"
                    variant="success"
                    onClick={updatePost}>
                      Save Post
                    </Button>
                      }
              <Button
                className="btn-sm ms-2 w-25"
                variant="danger"
                onClick={deleteResponse}
              >
                <i className="bi bi-file-x"> Delete post</i>
              </Button>
            </div>
          ) : null}
        </Card>
      </div>
    );
  }
  // ------------------------
  // ------------------------
  // POST CARD
  // each POST. gets mapped in return
  function Post({
    id,
    post,
    poster_first_name,
    poster_last_name,
    post_date,
    post_category,
    poster_uid,
    post_title,
    currentUser,
    isAdmin,
    original_post_id,
  }) {
    const deletePost = async (e) => {
      e.preventDefault();
      responses.forEach(async (r) => {
        if (r.original_post_id === onePostId) {
          await deleteDoc(doc(db, "PostResponses", r.id));
        }
      });
      await deleteDoc(doc(db, "Posts", id));
      setOnePostId("");
    };

    const [postEditor, setPostEditor] = useState(false)
    const [postUpdate, setPostUpdate] = useState(post)
    const [postTitleUpdate, setPostTitleUpdate] = useState(post_title)
    const [postCategoryUpdate, setPostCategoryUpdate] = useState(post_category)

    // udpate post on fb
const updatePost = async (e) => {
  e.preventDefault();
  const updatePostRef = doc(db, "Posts", id)
  await updateDoc(updatePostRef, {
post: postUpdate,
poster_uid: currentUser.uid,
poster_first_name: userFirstName,
poster_last_name: UserLastName,
poster_email: currentUser.email,
post_title: postTitleUpdate,
post_category: postCategoryUpdate,
  })
  setPostEditor(false)
}

    // clears onePost state
    function setPostId(e) {
      e.preventDefault();
      setPost("");
      setPostTitle("");
      setOnePostId(id);
    }

    return (
      <div key={id} className="d-flex flex-column align-items-center">
        <Card bg="light" className="w-100 m-3">
          <Card.Body>
            <div className="d-flex justify-content-start">
              {/* get name from profiles in case of name change later */}
              {profiles &&
                profiles.map((p) => {
                  return p.uid === poster_uid ? (
                    <div key={p.uid} className="pe-2">
                      <strong>
                        {p.first_name} {p.last_name}
                      </strong>
                    </div>
                  ) : null;
                })}

              <p>{post_date && post_date.toDate().toDateString()}</p>
            </div>

{postEditor &&
          <Row className="mb-3">
          <Col md={3}>
          <h5>Category:</h5>
          </Col>
          <Col>
        <Form>
<Form.Control 
defaultValue={postCategoryUpdate} 
type="text"
id="post_title"
onChange={(e) => setPostCategoryUpdate(e.target.value)}
/>
</Form>
          </Col>
        </Row>}

           {postEditor ?
          <>
          <Row className="mb-3">
            <Col md={3}>
            <h5>Title:</h5>
            </Col>
            <Col>
          <Form>
  <Form.Control 
  defaultValue={postTitleUpdate} 
  type="text"
  id="post_title"
  onChange={(e) => setPostTitleUpdate(e.target.value)}
  // plaintext={postEditor}
  // readOnly={postEditor}
  // onClick={() => setPostEditor(true)}
  />
</Form>
            </Col>
          </Row>
          </> 
          :
          <>
          <h3> <strong>{post_title}</strong></h3>
          </> 
          }
            
          
          {/* SHOW AND EDIT POST AREA */}
            {postEditor ?
          <>
<Form>
  <Form.Control defaultValue={post} 
  rows="7"
  as="textarea"
  id="post"
  onChange={(e) => setPostUpdate(e.target.value)}
  // plaintext={postEditor}
  // readOnly={postEditor}
  // onClick={() => setPostEditor(true)}
  />
</Form>
          </>  
          :
          <>
          {post}
          </>  
          }

            {/* <Card.Footer className="bg-light"> */}
            {/* <Button className="btn-sm me-2" 
variant="primary" 
// onClick={deletePost}
>See Responses and Reply
    </Button> */}

            <div className="pt-3">
              {!onePostId && (
                <Button className="btn-sm w-100" onClick={setPostId}>
                  More
                </Button>
              )}

              {currentUser &&
              (poster_uid === currentUser.uid || isAdmin === true) ? (
                <>
                  {/* <Button className="btn-sm ms-2" variant="warning"
       onClick={deletePost}
       >
      <i className="bi bi-pencil-fill"></i> Edit post
    </Button> */}

                  {onePostId && (
                    <>
                   {!postEditor &&
                    <Button
                    className="btn-sm ms-2"
                    variant="warning"
                    onClick={() => setPostEditor(!postEditor)}
                    >
                      Edit Post
                    </Button>
                      }
                   {postEditor &&
                    <Button
                    className="btn-sm ms-2"
                    variant="success"
                    onClick={updatePost}
                    
                    // onClick={updatePost}
                    >
                      Save Post
                    </Button>
                      }
                    <Button
                      className="btn-sm ms-2"
                      variant="danger"
                      onClick={deletePost}
                      >
                      <i className="bi bi-file-x"> Delete post and responses</i>
                    </Button>
                      </>
                  )}
                </>
              ) : null}
            </div>
            {/* </Card.Footer> */}
          </Card.Body>
        </Card>
      </div>
    );
  }
  // END EACH POST CARD -------------------------
  // ---------------------------------

const [q, setQ] = useState("")

const filterPosts = posts.filter(
  (a) =>
  a.post_title.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
  a.poster_first_name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
  a.poster_last_name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
  a.post.toUpperCase().indexOf(q.toUpperCase()) > -1 
);


  // list unique categories. no dupin
  const postCat = [...new Map(filterPosts.map((o) => [o.post_category, o])).values()];

  return (
    <>
      {/* <Helmet htmlAttributes>
        <title>AG Foster Care Network Discuss</title>
        <meta property="og:url" content="https://www.agfostercare.network/discuss" />
      </Helmet> */}

      <Container className="py-4">
        <h1 className="text-center">Discuss {category}</h1>

        {error && <div>{error}</div>}
        {loading && <Alert variant="info">loading...</Alert>}

        <Row>
          {!onePostId && (
            <Col md={4} className="border p-3 m-2">
              <h5>Categories</h5>

              {category && (
                <Button
                  variant="warning"
                  className="w-100 mb-3"
                  onClick={() => setCategory("")}
                >
                  Clear Filter
                </Button>
              )}
              {/* list cat buttons */}
              {posts &&
                postCat.map((cat) => (
                  <div key={cat.id}>
                    {/* {posts && posts.map((cat) => <div key={cat.id}> */}

                    <Button
                      variant="primary"
                      className="w-100 mb-1"
                      onClick={() => setCategory(cat.post_category)}
                    >
                      {cat.post_category}
                    </Button>
                  </div>
                ))}
              {/* end cat buttons */}
            </Col>
          )}

          {/* Page Content -------------- */}

          <Col className="border p-3 m-2">
            {/* filter */}
          <Form.Control
          type="search"
          name="search-form"
          id="search-form"
          placeholder="Filter by keyword"
          value={q}
          onChange={(e) => setQ(e.target.value)}

          />
            {/* ---------- show posts --------------- */}
            {/* filtered by category */}
            {category && (
              <div className=" text-center">{/* <h5>{category}</h5> */}</div>
            )}

            {posts &&
              !onePostId &&
              filterPosts.map((post) => {
                return post.post_category === category ? (
                  <Post
                    key={post.id}
                    currentUser={currentUser}
                    isAdmin={isAdmin}
                    {...post}
                  />
                ) : null;
              })}

            {/* if no category selected show this instead */}
            {/* showes all posts if no category selected */}
            {!category &&
              !onePostId &&
              filterPosts.map((post) => (
                <Post
                  key={post.id}
                  currentUser={currentUser}
                  isAdmin={isAdmin}
                  {...post}
                />
              ))}

            {/* if single view show button */}
            {onePostId && (
              <>
                <Button onClick={clearOnePostId}>
                  <AiOutlineArrowLeft />
                  Back to main
                </Button>
              </>
            )}

            {/* ---------- show single post by id --------------- */}
            {/* ---------- when there is id in onePostId --------------- */}
            {posts &&
              onePostId &&
              filterPosts.map((post) => {
                return post.id === onePostId ? (
                  <>
                    <Post
                      key={post.id}
                      currentUser={currentUser}
                      isAdmin={isAdmin}
                      {...post}
                    />

                    <Card className="p-4">
                      <h5>Respond to this post</h5>
                      {isProfile ? (
                        <Form id="responseForm" onSubmit={handleResponsePost}>
                          <Form.Group controlId="message" className="mb-4">
                            {/* <Form.Label>Post Body:</Form.Label> */}
                            <Form.Control
                              as="textarea"
                              rows="3"
                              required
                              value={response}
                              // defaultValue={response}
                              onChange={(e) => setResponse(e.target.value)}
                            ></Form.Control>
                          </Form.Group>

                          <Button
                            // disabled={sent}
                            className="w-25"
                            variant="primary"
                            type="submit"
                          >
                            Respond
                          </Button>
                        </Form>
                      ) : (
                        <Alert variant="warning">
                          You need a profile to post. You can create one in your
                          dashboard.
                        </Alert>
                      )}
                    </Card>
                  </>
                ) : null;
              })}

            {/* RESPONSES HERE */}
            {/* only show onePostId responses from responses */}
            {responses &&
              responses.map((r) => {
                return r.original_post_id === onePostId ? (
                  <Response
                    key={r.id}
                    currentUser={currentUser}
                    isAdmin={isAdmin}
                    {...r}
                  />
                ) : null;
              })}

            {/* main level post form to Posts */}
            {!onePostId && (
              <Card className="bg-light p-4">
                {isProfile ? (
                  <Form onSubmit={handlePost}>
                    <h5>{userFirstName}, make a new post</h5>
                    {category && (
                      <p>
                        in selected category: <strong>{category}</strong>
                      </p>
                    )}
                    <Form.Group controlId="category">
                      <Form.Label>Category:</Form.Label>
                      <Form.Label>
                        <em>
                          <small>
                            (adds a new category if it doesn't already exist.)
                          </small>
                        </em>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        required
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3" controlId="category">
                      <Form.Label>Post Title:</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        value={postTitle}
                        onChange={(e) => setPostTitle(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3 mb-4" controlId="message">
                      <Form.Label>Post Body:</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        required
                        value={post}
                        // defaultValue={post}
                        onChange={(e) => setPost(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Button
                      // disabled={sent}
                      className="w-100"
                      variant="primary"
                      type="submit"
                    >
                      Post
                    </Button>
                  </Form>
                ) : (
                  <Alert variant="warning">
                    You need a profile to post. You can create one in your
                    dashboard.
                  </Alert>
                )}
              </Card>
            )}
            {/* end show form */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
