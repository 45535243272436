export default function NetworkLogoHorz({fill, secondary}) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1779 426"
                            height="100%"
                            width="100%"
            >
<g fill={fill}>

{/* fostercare */}
<path fill={secondary} d="M631 189.4V68h66.2v27.2h-36.6v20.7H696V143h-35.3v46.3zM708.7 131.5c0-37.6 27-65.8 64-65.8a63 63 0 0164.2 62.2c0 36-28 63.8-62.7 63.8-33.8 0-65.5-24.3-65.5-60.2zm97.2-2.7c0-19-13.6-35.9-33.2-35.9-19.3 0-33 16.3-33 35.5 0 20 13.6 36 33.5 36 19.2 0 32.7-16.2 32.7-35.6zM901.3 103.4c-1.3-8.7-5-11.8-11-11.8-5.8 0-10.7 4-10.7 9.5 0 7.2 6.6 10.5 19.9 16.3 26.7 11.5 33.4 21.1 33.4 37 0 23.5-16 37.3-42.3 37.3-26.4 0-43.8-13.8-43.8-39.9v-2.6h30.8c.2 10 5.1 16.6 12.8 16.6a11.6 11.6 0 0011.7-11.7c0-9.6-13.5-14-24.5-18.3-19.9-8-28.9-18.6-28.9-34.3 0-20.7 20-35.8 42.4-35.8a46.2 46.2 0 0121.5 5c13 6.8 19.2 16.5 19.3 32.7h-30.6zM962.3 189.4V95.2h-24V68h78.8v27.2h-23.8v94.2zM1029.6 189.4V68h67.5v27.2h-37.8V114h36.6v27.2h-36.6v20.9h37.8v27.2zM1174.4 189.4l-28-39.2h-.4v39.2h-29.7V68h39.7a75 75 0 0128.7 4.4A40.2 40.2 0 011207 110c0 19.2-11.4 35-30.2 38.2l33 41.4zm-17-60.4c12.4 0 20-5.7 20-17.4 0-10.5-8-16.4-19.6-16.4H1146V129h11.5zM1362.2 114a31.7 31.7 0 00-29.4-21.1c-19.4 0-32.5 16.6-32.5 35.5 0 19.6 13.4 36 32.7 36 12.3 0 22.8-6.8 29.2-20.5h32.5a62.7 62.7 0 01-61.4 47.8 63.2 63.2 0 01-64-64.8 62.8 62.8 0 0162.5-61.3c16.7 0 30.2 4.6 42.4 15a56.5 56.5 0 0120.5 33.5zM1482.5 189.4l-6-17.6h-42.1l-6.4 17.6h-33.3L1443.4 68h24.5l48.2 121.5zm-26.6-83.5l-.5-.2-12.4 41.5h25.4zM1586.7 189.4l-28-39.2h-.4v39.2h-29.7V68h39.8a75 75 0 0128.7 4.4 40.2 40.2 0 0122.3 37.5c0 19.2-11.5 35-30.2 38.2l33 41.4zm-16.9-60.4c12.3 0 19.9-5.7 19.9-17.4 0-10.5-8-16.4-19.6-16.4h-11.8V129h11.5zM1635.8 189.4V68h67.5v27.2h-37.8V114h36.6v27.2h-36.6v20.9h37.8v27.2z"/>

{/* network */}
<path d="M714.8 355l-72.2-107h-.3v107h-12.1V233.4h16.4l67.8 100.9h.4V233.4h12.1V355h-12.1zM750.9 355V233.4h66.5v11H763v43.7h52.6v11H763V344h54.4v11H751zM856.4 355V244.4h-28v-11h67.9v11h-27.8V355zM1003.2 355l-26.8-99.7h-.3L949.5 355h-15.6l-34.7-121.6h12.5l29.7 106h.4l28.9-106H982l29 105.8h.4l29.7-105.8h12.4L1018.7 355h-15.5zM1126.4 357.1c-33.2 0-64.2-27-64.2-62.3 0-35 28.6-63.5 64.2-63.5a63.9 63.9 0 0164.4 62.9 64 64 0 01-64.4 62.9zm-.1-114.8c-28.8 0-52 24.3-52 52.6a52.6 52.6 0 0052.1 51.2c29.1 0 52.3-23.8 52.3-51.8a52.5 52.5 0 00-52.4-52zM1274.8 355l-41.3-55h11.9c21.5 0 34.3-11 34.3-27.6 0-11.5-7-28-35-28h-23.2V355h-12.1V233.4h36.6c31.7 0 45.8 18.7 45.8 39.3 0 19.7-14.8 35.3-36.1 37L1290 355h-15.3zM1379.4 355l-40.6-52.7-15.8 19.3V355h-12.2V233.4h12.2v69.8h.3l55.7-69.8h15.6l-48.1 59.3 48.2 62.3h-15.3z"/>



  <path d="M172.5 325.4s-16-9.8-15.7-26.6 19.2-42.9 21.2-53 0-15.7 0-15.7 15.3 13.3 13.3 33.3c-1.3 14.2-14.4 31.4-18.6 41.6s-.2 20.4-.2 20.4M186.3 343.4s-10-6.2-10-16.7 12.2-27 13.4-33.4 0-9.9 0-9.9 9.7 8.4 8.5 21c-1 9-9.2 19.8-11.8 26.2a19.4 19.4 0 00-.1 12.9"/>

  <path d="M478.3 200.6h-92.5l31.8 32.5h24.5v76a103.4 103.4 0 01-28.2 10.1 169.5 169.5 0 01-35.6 3.5q-24.3 0-44-8.5a95.1 95.1 0 01-33.2-23.4 104.5 104.5 0 01-21-35A123.8 123.8 0 01273 213a121.4 121.4 0 017.3-42.3 107.2 107.2 0 0120.9-35 96.8 96.8 0 0132.8-23.7q19.3-8.7 43.3-8.7 23.5 0 42.7 8.1t31.5 21.6l26.6-27.8Q461 89 435.3 79.3t-58.5-9.7q-31.3 0-58 10.5a139 139 0 00-46.2 29.1 132.4 132.4 0 00-30.5 45.2l-2.9 7.8-36.7-85.6h-34.8L46 357h42.5l30.6-74.2 13.5-33.6L184 121l51.2 128.3s13 34 18.6 43.4a122.5 122.5 0 0018.3 24 135.2 135.2 0 0043.9 28.8l2.2 1q26.9 10.6 59 10.6a237 237 0 0055.6-6.1A198.6 198.6 0 00480 333V200.6zM503.8 103a6.8 6.8 0 002.7-.6 2.1 2.1 0 001.3-2.2 2 2 0 00-1.2-2 6.2 6.2 0 00-2.4-.5h-3v5.2h2.6zm-6-8h6.5a10.9 10.9 0 012.5.2 6.6 6.6 0 012.2 1 4.7 4.7 0 011.5 1.6 5 5 0 01.6 2.5 4.7 4.7 0 01-1.2 3.2 5.8 5.8 0 01-3 1.7l4.9 8h-4l-4.3-7.6h-2.3v7.6h-3.3zm-7.3 9.1a13.2 13.2 0 001.1 5.3 14.1 14.1 0 003 4.4 13.7 13.7 0 0019.3 0 14.5 14.5 0 003-4.4 13.3 13.3 0 001-5.3 13.5 13.5 0 00-1-5.3 14 14 0 00-3-4.4 13.8 13.8 0 00-4.3-2.9 13.7 13.7 0 00-18 7.3 13.3 13.3 0 00-1.1 5.3m-2.6 0a15.7 15.7 0 011.3-6.3 16 16 0 013.5-5.1 16.6 16.6 0 015.2-3.5 15.8 15.8 0 016.3-1.3 15.6 15.6 0 016.3 1.3 16.8 16.8 0 015.2 3.5 16.3 16.3 0 013.5 5.1 15.8 15.8 0 011.3 6.3 15.3 15.3 0 01-1.3 6.3 16 16 0 01-8.7 8.5 16.1 16.1 0 01-6.3 1.3 16.2 16.2 0 01-15-9.8 15.3 15.3 0 01-1.3-6.3"/>
</g>
</svg>
        </>
    )
}
