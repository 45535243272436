import {
  Button,
  Container,
  Row,
  Col,
  Alert,
  Card,
  Form,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { db } from "../../util/firebase";
import { onSnapshot, doc, deleteDoc, collection } from "firebase/firestore";
import { useAuth } from "../../util/AuthContext";
import { CSVLink, CSVDownload } from "react-csv";

export default function Admin() {
  const { currentUser } = useAuth();

  const [mail, setMail] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [showCheck, setShowCheck] = useState(true);
  const [pageContent, setPageContent] = useState("Overview");
  const [q, setQ] = useState("");

  const [totalAgAffiliates, setTotalAgAffiliates] = useState(0)
  const [newUsers, setNewUsers] = useState(0)
  // const [today] = (new Date())

  const [days, setDays] = useState(30);
  // const [filterBy, setFilterBy] = useState("")

  // check if admin
  async function CheckAdmin() {
    console.log("start check admin");

    if (currentUser) {
      // console.log("user logged in.")

      profiles.forEach((f) => {
        // console.log(f.id)
        if (f.id === currentUser.uid) {
          // console.log(f[0].first_name)
          console.log("profile uid match");

          if (f.isAdmin ? "Yes" : null) {
            console.log("got an admin");
            setIsAdmin(true);
            setError("");
            setShowCheck(false);
          } else {
            // console.log("didn't connect admin")
            setError("Sorry. We're closed.");
          }
        }
      });
    } //end if currentUser
  } //end check admin

  useEffect(() => {
    async function GetMail() {
      return onSnapshot(collection(db, "mail"), (snapshot) => {
        setMail(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
        );
      });
    }
    async function GetUsers() {
      return onSnapshot(collection(db, "Users"), (snapshot) => {
        // console.log('getting profiles')
        setProfiles(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          );
         
      });
    }

    try {
      if (currentUser) {
        if (profiles < 1) {
          GetUsers();
        }

        if (mail < 1) {
          // load mail into mail
          GetMail();
        }

        if (profiles.length < 1) {
          setTimeout(() => CheckAdmin(), 2000);
        } else {
          CheckAdmin();
        }
      } else {
        setError("Not Logged In");
        setShowCheck(false);
      }
    } catch (e) {
      setError(e);
    }
  }, []); // eslint-disable-this-line

  const filterData = profiles.filter(
    (f) =>
      f.last_name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      f.city.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      f.state.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      f.church.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
      f.first_name.toUpperCase().indexOf(q.toUpperCase()) > -1
  );

  return (
    <>
      {error && <Alert variant="warning">{error}</Alert>}

      {showCheck && (
        <div className="d-flex justify-content-center align-items-center py-5">
          <Button onClick={() => CheckAdmin()}>Check In</Button>
        </div>
      )}

      {isAdmin && (
        <Container
          fluid
          className="m-0 pt-3 ps-2 pb-1 bg-color1 text-white d-flex justify-content-between align-items-bottom"
        >
          <h4>admin</h4>
          <p> {pageContent}</p>
        </Container>
      )}
      <Container fluid className="" style={{ minHeight: "87vh" }}>
        {isAdmin && (
          <Row className="mt-3">
            <Col md={2} className="d-flex flex-column">
              <Button
                variant="light"
                size="sm"
                onClick={() => setPageContent("Overview")}
              >
                <a>Overview</a>
              </Button>

              <Button
                variant="light"
                size="sm"
                onClick={() => setPageContent("Mail")}
              >
                <a>All sent mail</a>
              </Button>

              <Button
                variant="light"
                size="sm"
                onClick={() => setPageContent("Profiles")}
              >
                <a>All Profiles</a>
              </Button>
            </Col>

            {/* MAIL LIST ------------------------ */}
            {pageContent === "Mail" && (
              <Col>
                <h4>All mail list</h4>
                <p>All items that are sent to process to email. This includes user to user messages and contact us form.</p>
                {mail && mail.map((m) => <MailCard key={m.id} {...m} />)}
              </Col>
            )}
            {/* end mail content */}

            {/* PROFILE LIST --------------------- */}
            {pageContent === "Profiles" && (
              <Col>
                <h4>All Profiles</h4> <CSVLink data={profiles}>Download</CSVLink>
                <Form.Control
                  type="search"
                  name="search-form"
                  id="search-form"
                  placeholder="filter by..."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                {profiles &&
                  filterData.map((p) => <ProfileCard key={p.id} {...p} />)}
              </Col>
            )}
            {/* END PROFILE LIST -------------------- */}

            {pageContent === "Overview" && (
              <Col>
                <h4>Overview</h4>
                {/* <p className="d-flex align-content-bottom">In the last 
    <Form.Control
    value={days}
    onChange={(e) => setDays(e.target.value)}
    className="text-center"
    style={{width:`60px`}}
    />
    days</p> */}
                <Row>
                  {/* STARTOVERVIEW LIST */}

                  <Col
                    style={{ maxWidth: "200px" }}
                    className="border border-2 p-4 d-flex flex-column align-items-center"
                  >
                    <h5>Total Users</h5>
                    <p className="fw-bold fs-3">{profiles.length}</p>
                  </Col>

                  {/* <Col
                    style={{ maxWidth: "200px" }}
                    className="border border-2 p-4 d-flex flex-column align-items-center"
                  >
                    <h5>New users</h5>
                    <p className="fw-bold fs-3">
                      {newUsers}
                    </p>
                  </Col> */}

                  {/* <Col
                    style={{ maxWidth: "200px" }}
                    className="border border-2 p-4 d-flex flex-column align-items-center"
                  >
                    <h5>AG Affiliated</h5>
                    <p className="fw-bold fs-3">
                        {profiles.forEach(a => {
                            if (a.ag_affiliated === "Yes") {setTotalAgAffiliates(totalAgAffiliates + 1)
                            }
                        })}</p>
                  </Col> */}

                  <Col
                    style={{ maxWidth: "200px" }}
                    className="border border-2 p-4 d-flex flex-column align-items-center"
                  >
                    <h5>Foster Kids</h5>
                    <p className="fw-bold fs-3">
                        {profiles.reduce(function(prev, current) {
                        return prev + +current.numberOfFosterKids
                    }, 0)}</p>
                    <p className="fs-small">with our foster parents.</p>
                  </Col>
                </Row>
              </Col>
            )}
            {/* end page col */}
          </Row>
        )}
        {/* end of isAdmin */}
      </Container>
    </>
  );
}

function MailCard({
  id,
  message,
  subject,
  date,
  to,
  state,
  replyTo,
  delivery,
}) {
  const deleteMail = async (e) => {
    e.preventDefault();
    await deleteDoc(doc(db, "mail", id));
  };
  return (
    <div key={id} className="d-flex flex-column alignt- items-center">
      <Card className="border border-3 p-3 my-2">
        {date && <p>{date.toDate().toDateString()}</p>}
        {replyTo && <p>From: {replyTo}</p>}
        {to && <p>To: {to}</p>}
        {subject && <p>{subject}</p>}
        {message && <>
          <p className="fw-bold">
            message:
          </p>
            <p>
                {message.text}
            </p> 
        </>
        }
        {state && <p>state: {state}</p>}
        {delivery && <p>Delivery attempts: {delivery.attempts}</p>}
        {delivery && <p>endtime: {delivery.endTime.toDate().toDateString()}</p>}
        {delivery && <p>Delivery state: {delivery.state}</p>}

        <Button variant="danger" onClick={deleteMail}>
          Delete
        </Button>
      </Card>
    </div>
  );
}

function ProfileCard({
  ag_affiliated,
  ag_church,
  ag_credentialed,
  church,
  city,
  comments,
  date,
  email,
  first_name,
  fosterParent,
  last_name,
  numberOfFosterKids,
  org_ag,
  org_other,
  org_ag_title,
  org_other_title,
  org_ag_url,
  org_other_url,
  social_media,
  state,
  street_address,
  uid,
  user_zip,
  isAdmin,
  id,
}) {
  return (
    <div key={id} className="border border-1 p-1 my-1 small">
      <p className="fw-bold">
        {first_name} {last_name} | {email}{" "}
      </p>
      {isAdmin && <p>is admin</p>}

      {social_media && (
        <a
        className="fw-bold"
          href={`https://${social_media}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {social_media}
        </a>
      )}
      {street_address && (
        <p>
          {street_address} {city !== "NONE" && city} {state !== "NONE" && state}{" "}
          {user_zip}
        </p>
      )}

      {ag_affiliated && <p>AG: {ag_affiliated}</p>}

      {church && <p>Church: {church}</p>}

      {ag_church && <p>AG Church: {church}</p>}

      {fosterParent === "Yes" && (
        <p>Foster Parent. {numberOfFosterKids} Kids.</p>
      )}

      <p>AG Credentialed: {ag_credentialed}</p>

      {org_ag && <p>AG Org: {org_ag}</p>}
      {org_ag_title && <p>Title: {org_ag_title}</p>}
      {org_ag_url && <p>AG Org url: {org_ag_url}</p>}
      {org_other && <p>Org not AG: {org_other} </p>}
      {org_other_title && <p>Title: {org_other_title}</p>}
      {org_other_url && <p>Org url: {org_other_url}</p>}

      {comments && (
        <>
          <p>Comments:</p>
          <p> {comments}</p>
        </>
      )}

      {date && <p>Date last saved profile: {date.toDate().toDateString()}</p>}
      {/* {ag_affiliated && ag_affiliated} */}
    </div>
  );
}

