import { useState } from "react";
import { db } from "../util/firebase";
import { useEffect } from "react";

import { Container, Form, Button, Card } from "react-bootstrap";
import { useAuth } from "../util/AuthContext";
import { addDoc, collection } from "@firebase/firestore";


export default function Contact() {

  const [msg, setMsg] = useState("")
  // const [emailTo, setEmailTo] = useState('')
  const [msgSent, setMsgSent] = useState(false)
  const { currentUser } = useAuth();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [date] = useState(new Date());
const [human, setHuman] = useState(false)

useEffect(() => {
    if (currentUser) {
      setHuman(true)
      setEmail(currentUser.email)
    }
    
  }, [currentUser])

function humanCheck() {
  setHuman(!human)
}
  
  async function SendIt(e) {
    e.preventDefault();
    setLoading(true);
    await addDoc(collection(db, "mail"), {
      form:"contact us",
      date: date,
      // to: `${emailTo}`,
      // to: "james@mediavelocity.com,james.gerhold@gmail.com",
      to: "abixler@agfostercare.network,kbixler@agfsa.org",
      replyTo:`${email}`,
      message: {
        subject: `Message from ${email} via AG Foster Care Network Contact Us Page`,
        text: `${msg}`,
        html: `${msg}`,
      }
    })
  
    setMsgSent(true)
    setMsg("")
    }
    
    return (
        <Container className="py-3 d-flex flex-column align-items-center justify-content-center" style={{minHeight:"88vh"}}>
          <Card className="text-center p-4">

<h5>
         Contact Us
</h5>
{msgSent?<>
<h5>Message sent.</h5>
<p>Thanks for reaching out to the team!</p>
</>:<>
<Form onSubmit={SendIt} className="bg-white p-4">
      <p>You can send the AG Foster Care Network team a message here:</p>
      <Form.Group controlId="email" className="my-2">
            <Form.Control
            required
            placeholder="email"
            defaultValue={email} 
            onInput={e => setEmail(e.target.value)} type="email" />
            </Form.Group>

                
            <Form.Group controlId="message">
            <Form.Control
            required
            as="textarea" 
            value={msg} 
            placeholder="Your message..."
            onInput={e => setMsg(e.target.value)} type="text" />
            </Form.Group>
  
            {!human &&
        <Button
        onClick={humanCheck}
        className="my-1 btn-secondary"
        >I am indeed human</Button>
        }
        
        {human &&
        <Button disabled={loading} type="submit" className="my-1"
        >Send Email</Button>
        }
      </Form>
</>
}
      
</Card>
        </Container>
    )
}


