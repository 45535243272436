import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import ReactGA from 'react-ga'
import {createBrowserHistory} from 'history'
import { useEffect } from "react";

import MenuMain from './components/MenuMain'
import { AuthProvider, useAuth } from './util/AuthContext';

// import PrivateRoute from './components/PrivateRoute';

import Home from './pages/home.js';
import PostPage from './pages/Discuss';
import Signin from './pages/signin';
import Profile from './pages/profile'
import Signup from './pages/signup';
import ForgotPassword from './pages/forgotPassword';
import Pagenotfound from './pages/pagenotfound';
import Contact from './pages/contact';
import Resources from './pages/resources.js';

import UpdateProfile from './pages/UpdateProfile';
import Dashboard from './pages/dashboard';
import Footer from './components/Footer';
import UpdatePassword from './pages/updatePassword';
import DirectoryPage from './pages/DirectoryPage';
import DeleteAccount from './pages/DeleteAccount';

import Admin from './pages/admin/Admin';
import ProfileImageUpdater from './pages/ProfileImage';

import Sketch from './pages/sketch';
// import Conference from './pages/Conference';

// import Messages from './pages/Messages'
// import Sort from './pages/sort';

function App() {

  ReactGA.initialize('UA-145080690-1')

  const history = createBrowserHistory()
  history.listen(location => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
   
  }, [history])



  return (
    <>
    <Router>
      <AuthProvider>

    <header>
    <MenuMain currentUser="currentUser" />
    </header>

<Routes>

    <Route path="/" element={<Home />} />

    {/* <Route path="sk" element={<Sketch />} /> */}
    <Route path="signin" element={<Signin />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/update-profile-image" element={<ProfileImageUpdater />} />
    <Route path="/discuss" element={<PostPage />} />
    <Route path="/resources" element={<Resources />} />
   
{/* <Route path="/conference" element={<Conference />} /> */}

    <Route path="/directory" element={
    <PrivateRoute>
      <DirectoryPage/>
    </PrivateRoute>} />
  
    <Route path="/profile" element={
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    } />

    <Route path="/update-password" element={
      <PrivateRoute>
        <UpdatePassword />
      </PrivateRoute>
      } />
    {/* <Route path="/map" element={
      <PrivateRoute>
        <mapPage />
      </PrivateRoute>
      } /> */}
    <Route path="/admin" element={
      <PrivateRoute>
        <Admin />
      </PrivateRoute>
    } />
    <Route path="/dashboard" element={
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
      } />
    <Route path="/update-profile" element={
      <PrivateRoute>
        <UpdateProfile />
      </PrivateRoute>
      } />
    <Route path="/delete-account" element={
      <PrivateRoute>
        <DeleteAccount />
      </PrivateRoute>
    } />
    <Route path="*" element={<Pagenotfound />} />

</Routes>

<Footer />

      </AuthProvider>

      </Router>
  </>
  );
}

export default App;


function PrivateRoute({children}) {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/signin" />
}

// https://codesandbox.io/s/react-router-v6-security-gojb0