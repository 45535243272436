import { Navbar, Nav } from "react-bootstrap";
import { useAuth } from "../util/AuthContext";

import { Link } from "react-router-dom";

import ProfileIcon from "../graphics/ProfileIcon";

export default function MenuMain() {
  const { currentUser } = useAuth()
  
    return(  
        <>

<Navbar className="menuMain d-flex justify-content-center text-uppercase" expand="md" variant="dark">
  {/* <Navbar.Brand href="/">AGFosterCare.Network</Navbar.Brand> */}
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="justify-items-around" style={{width:"100%"}}>
      <Link to="/">Home</Link>

      <a href="https://agfostercare.network/conference/" 
      rel="noopener noreferrer">Conference</a>

 
      
      {/* <Link to="/about">About</Link> */}
      {!currentUser && 
      <Link to="/signin">Sign In</Link>
    }
    {/* <Link to="/discuss">Discuss</Link> */}

    {currentUser &&
    <Link to="/directory">Directory</Link>
    }

<Link to="/resources">Resources</Link>
<Link to="/contact">Contact</Link>

<a href="https://giving.ag.org/donate/ag-national-office/natl-foster-care" 
      // target="_parent"
      rel="noopener noreferrer">Give</a>

{currentUser &&
<Link to="/dashboard">
{/* {currentUser.email} */}
<ProfileIcon color="var(--color1)" />
</Link>
}
{/* {currentUser && 
        <Link to="/" onClick={handleLogout}>
        Sign Out
    </Link>
} */}

     
      {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown> */}
    {/* {error && <small>{error}</small>} */}
    </Nav>
    {/* <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-success">Search</Button>
    </Form> */}
  </Navbar.Collapse>
</Navbar>



  </>     
    )
}

