export default function Megaphone() {
    return (
        <>

<svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 800 800"
>

  <path d="M731.7 59.5c.6-11.3-8-23.2-19.8-22.8-5.4.3-9.7 2.5-14 6.3-12.7 12-25.2 24.3-38.3 35.7a658.8 658.8 0 01-239.3 128c-12.1 3.8-24.8 5.6-36.1 11.7H179.5c-13.8.3-24.9 10.7-27.6 24.9H99.6c-18.7 0-32.8 18.8-31.3 37.3v162.2c-2.3 20.9 10.8 41.7 31.3 42 16 .4 36.2-.3 52.3 0 6.2 31 35.8 24.6 59.4 25l27.5 149.4c5.4 24 5.7 50.7 18.9 72 14.8 23.6 41.8 39.5 68.5 30.7l-1.8.8c37.7-8.9 54.9-54.6 47.2-90.5l-5.6-30.5-24.2-132h41.8c41.7 17.2 85.9 26.9 127 46a613 613 0 01144 89.5c11.5 9.8 22.6 20 33.2 30.7 4.5 4.5 8.7 9.5 14.1 12.4 13.6 8.5 30.3-3 29.8-18.7V653 59.5zm-624 381.8c.2-38.2.3-115.8 0-154.6h42.5v154.6zm83 24.8c.3-63 0-141.4.2-204.4 52 .3 119.3 0 171.3.1q.4 102.2 0 204.4H190.9zM273 626l-21.3-116.3h49.8l28 152.8c2.5 14 7.3 28.6 2 42.5-7.2 19.3-28.2 21.1-39.5 4.4-7-10-7.7-22.7-10-34.4l-9-49zm39.2 95.7a6.4 6.4 0 012.1-.8l-2.1.8zm356-119.3c-77-64.3-170.5-101.9-265.1-130.6V256.5c106-28 206.5-77.7 289-151v517.3q-11.7-10.6-24-20.5z" fill="#e4a16b"/>
</svg>

        </>
    )
}
