import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, Container, Alert } from "react-bootstrap";
import { useAuth } from "../util/AuthContext";
import { db } from "../util/firebase";
// import { Link } from "react-router-dom";
import {getDoc, setDoc, doc, updateDoc} from 'firebase/firestore'

export default function UpdateProfile() {
  const navigate = useNavigate()
  const { currentUser } = useAuth();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [success, setSuccess] = useState('')
  
  const [date] = useState(new Date());
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  // const [currentProfile, setCurrentProfile] = useState('')
  const [agAffiliated, setAgAffiliated] = useState('Yes');
  const [agCredentialed, setAgCredentialed] = useState('No');
const [userState, setUserState] = useState('NONE')
const [userCity, setUserCity] = useState('')
const [userZip, setUserZip] = useState('')
const [agOrg, setAgOrg] = useState('')
const [agOrgUrl, setAgOrgUrl] = useState('')
const [agOrgTitle, setAgOrgTitle] = useState('')
const [orgOther, setOrgOther] = useState('')
const [orgOtherTitle, setOrgOtherTitle] = useState('')
const [orgOtherUrl, setOrgOtherUrl] = useState('')
const [fosterParent, setFosterParent] =useState('No')
const [numberOfFosterKids, setNumberOfFosterKids] = useState('')
const [streetAddress, setStreetAddress] = useState('')
const [socialMedia, setSocialMedia] = useState('')
const [church, setChurch] = useState('')
const [agChurch, setAgChurch] = useState('Yes')
const [comments, setComments] = useState('')

  // load profile ------------------------
  useEffect(() => {
    setLoading(true)
    async function fetchProfile() {
        const docRef = doc(db, "Users", currentUser.uid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            console.log('Loading Profile');

            setUserState(docSnap.data().state)
setFirstName(docSnap.data().first_name)
setLastName(docSnap.data().last_name)
setPhone(docSnap.data().phone)
setUserCity(docSnap.data().city)
setUserZip(docSnap.data().user_zip)
setAgAffiliated(docSnap.data().ag_affiliated)
setAgCredentialed(docSnap.data().ag_credentialed)
setAgOrg(docSnap.data().org_ag)
setAgOrgUrl(docSnap.data().org_ag_url)
setAgOrgTitle(docSnap.data().org_ag_title)
setOrgOther(docSnap.data().org_other)
setOrgOtherTitle(docSnap.data().org_other_title)
setOrgOtherUrl(docSnap.data().org_other_url)
setFosterParent(docSnap.data().fosterParent)
setNumberOfFosterKids(docSnap.data().numberOfFosterKids)
setStreetAddress(docSnap.data().street_address)
setSocialMedia(docSnap.data().social_media)
setChurch(docSnap.data().church)
setAgChurch(docSnap.data().ag_church)
setComments(docSnap.data().comments)

          } else {
              console.log('no profile found.')

  //             const docRef = doc(db, "Users", currentUser.uid)
  // await setDoc(docRef, {
  //     date: date,
  //     email: currentUser.email,
  //     uid: currentUser.uid,
  //     first_name: firstName,
  //     last_name: lastName,
  //     ag_affiliated: agAffiliated,
  //     ag_church: agChurch,
  //     ag_credentialed: agCredentialed,
  //     church: church,
  //     city: userCity,
  //     comments: comments,
  //     fosterParent: fosterParent,
  //     numberOfFosterKids: numberOfFosterKids,
  //     org_ag: agOrg,
  //     org_ag_title: agOrgTitle,
  //     org_ag_url: agOrgUrl,
  //     org_other: orgOther,
  //     org_other_title: orgOtherTitle,
  //     org_other_url: orgOtherUrl,
  //     social_media: socialMedia,
  //     state: userState,
  //     street_address: streetAddress,
  //     user_zip: userZip,
  // },
  // {merge: true});

          }
      }

      try{
          fetchProfile();
      } catch (error) {
          setError(error)
      }
      setLoading(false);
  }, []);// eslint-disable-line
  // ------------------------
  

  // UPDATE EXISTING PROFILE //
  
const handleUpdateProfile = async (e) => {
  setLoading(true);
  e.preventDefault();
  const docRef = doc(db, "Users", currentUser.uid)
  await setDoc(docRef, {
      date: date,
      email: currentUser.email,
      uid: currentUser.uid,
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      ag_affiliated: agAffiliated,
      ag_church: agChurch,
      ag_credentialed: agCredentialed,
      church: church,
      city: userCity,
      comments: comments,
      fosterParent: fosterParent,
      numberOfFosterKids: numberOfFosterKids,
      org_ag: agOrg,
      org_ag_title: agOrgTitle,
      org_ag_url: agOrgUrl,
      org_other: orgOther,
      org_other_title: orgOtherTitle,
      org_other_url: orgOtherUrl,
      social_media: socialMedia,
      state: userState,
      street_address: streetAddress,
      user_zip: userZip,
  }, {merge: true});
  setLoading(false)
  navigate('/dashboard')
}
  // END UPDATE EXISTING PROFILE //

  // DELETE PROFILE
  // async function deleteProfile(e) {
  //   e.preventDefault();
  //   setLoading(true);
  //   await deleteDoc(doc(db, "Users", currentUser.uid))
  //   setLoading(false);
  // }
  // end delete user profile

  return (
    <Container className="my-3">
      {error && <Alert variant="warning">{error}</Alert>}

      <h1>Update Your profile</h1>
      
        <Container>
          <Form onSubmit={handleUpdateProfile}>
            <Form.Group className="mt-3 bg-light p-3 border">
              <Row>
                <Col>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="first_name"
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}

                    required
                  ></Form.Control>
                </Col>

                <Col>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="last_name"
                    defaultValue={lastName}
                    onChange={(e) => setLastName(e.target.value)}

                    required
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="pt-3">
                <Col md={6}>
                  <Form.Label>Phone (Required)</Form.Label>
                  <Form.Control
                    type="number"
                    id="phone"
                    defaultValue={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
   

            <Form.Group className="mt-3 bg-light p-3 border">
              <Row>
                <Col sm={10}>
                  <p>
                    I attend or am part of an Assemblies of God ministry or
                    organization.
                  </p>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    as="select"
                    id="ag_affiliated"
                    onChange={(e) => setAgAffiliated(e.target.value)}

                    value={agAffiliated}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mt-3 bg-light p-3 border">
              <Row>
                <Col sm={10} className="d-flex align-items-center">
                  <p>I am an Assemblies of God credentialed minister.</p>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    id="ag_credentialed"
                    as="select"
                    onChange={(e) => setAgCredentialed(e.target.value)}
                    value={agCredentialed}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mt-3 bg-light p-3 border">
            <Form.Label>My Assemblies of God employer <small>(if applicable)</small></Form.Label>
    <br /><small>ex. General Council of the Assemblies of God, District Council of the AG, Local AG Church, AG Family Servers Dba COMPACT, AG US Missions, AG University etc </small>
              <Form.Control
                id="org_ag"
                type="text"
                defaultValue={agOrg}
                onChange={(e) => setAgOrg(e.target.value)}
                placeholder="General Council of the AG, District Council of the AG, Local AG Church ..."
              ></Form.Control>

              <Form.Label className="mt-3">My Title/Role</Form.Label>
              <Form.Control
                id="org_ag_title"
                type="text"
                onChange={(e) => setAgOrgTitle(e.target.value)}
                defaultValue={agOrgTitle}
              ></Form.Control>

              <Form.Label className="mt-3">My AG employer website URL</Form.Label>
              <Row>
                <Col md={1} className="d-flex align-items-center">
                  <small>https://</small>
                </Col>
                <Col>
                  <Form.Control
                    id="org_ag_url"
                    onChange={(e) => setAgOrgUrl(e.target.value)}
                    defaultValue={agOrgUrl}
                    type="text"
                    placeholder="URL"
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>


            <Form.Group className="mt-3 bg-light p-3 border">
    <Form.Label>Name of my organization or corporation <small>(if applicable)</small></Form.Label>
    <Form.Control id="org_other" type="text"
    placeholder="ex. 501c3, for profit corporation etc"
    defaultValue={orgOther}
    onChange={(e) => setOrgOther(e.target.value)}
    ></Form.Control>
   
    <Form.Label className="mt-3">My Title/Role</Form.Label>
    <Form.Control id="org_other_title" type="text"
                        onChange={(e) => setOrgOtherTitle(e.target.value)}
                defaultValue={orgOtherTitle}
                ></Form.Control>

    <Form.Label className="mt-3">My organization or corporation website URL</Form.Label>
    <Row>
        <Col md={1} className="d-flex align-items-center">
        <small>https://</small>
        </Col>
        <Col>
    <Form.Control id="org_other_url" type="text" placeholder="URL"
                defaultValue={orgOtherUrl}
                onChange={(e) => setOrgOtherUrl(e.target.value)}
                ></Form.Control>
        </Col>
    </Row>
</Form.Group>



            <Form.Group className="mt-3 bg-light p-3 border">
              <Row>
                <Col sm={10} className="d-flex align-items-center">
                  <p>I am a foster parent.</p>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    as="select"
                    id="fosterParent"
                    value={fosterParent}
                    onChange={(e) => setFosterParent(e.target.value)}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Form.Control>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={10}>
                  <Form.Label>
                    Number of foster children in my home this year.
                    <small> (Kindship and non-kinship)</small>
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    inline
                    id="numberOfFosterKids"
                    type="number"
                    onChange={(e) => setNumberOfFosterKids(e.target.value)}
                    defaultValue={numberOfFosterKids}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className="mt-3 bg-light p-3 border">
              <Form.Label>
                Street Address{" "}
                <small>(Your street address will not be made public.)</small>
              </Form.Label>
              <Form.Control
                id="street_address"
                type="text"
                defaultValue={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                placeholder="Street Address"
              ></Form.Control>

              <Row className="mt-3">
                <Col>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    id="city"
                    type="text"
                    placeholder="City"
                    onChange={(e) => setUserCity(e.target.value)}
                    defaultValue={userCity}
                  ></Form.Control>
                </Col>
                <Col md={3}>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    required
                    id="state"
                    as="select"
                    onChange={(e) => setUserState(e.target.value)}
                    value={userState}
                  >
                    {/* <option value="NONE">None Selected</option> */}
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </Form.Control>
                </Col>
                <Col md={2}>
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    id="user_zip"
                    type="text"
                    placeholder="Zip"
                    onChange={(e) => setUserZip(e.target.value)}
                    defaultValue={userZip}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mt-3 bg-light p-3 border">
              <Form.Label>Social Media</Form.Label>
              <Row>
                <Col md={1} className="d-flex align-items-center">
                  <small>https://</small>
                </Col>
                <Col>
                  <Form.Control
                    id="social_media"
                    type="text"
                    placeholder="URL"
                    onChange={(e) => setSocialMedia(e.target.value)}
                    defaultValue={socialMedia}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mt-3 bg-light p-3 border">
              <Form.Label>The Name of my Church</Form.Label>
              <Form.Control
                id="church"
                type="text"
                onChange={(e) => setChurch(e.target.value)}
                defaultValue={church}
              ></Form.Control>

              <br />

              <Row>
                <Col sm={10}>
                  <p>My church is an Assemblies of God church.</p>
                </Col>
                <Col sm={2}>
                  <Form.Control
                    as="select"
                    id="ag_church"
                    onChange={(e) => setAgChurch(e.target.value)}
                    value={agChurch}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Comments about you or your organization</Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                id="comments"
                type="text"
                onChange={(e) => setComments(e.target.value)}
                defaultValue={comments}
              ></Form.Control>
            </Form.Group>


            <Container className="my-3 d-flex justify-content-center">
              <Button disabled={loading} type="submit">
                Update Profile
              </Button>

              {/* <Button
                disabled={loading}
                variant="danger"
                onClick={deleteProfile}
              >
                Delete Profile
              </Button> */}
            </Container>
            {/* <p className="text-center">
              <small>
                <em>
                  *Deleting profile will only remove your profile from the
                  network directory. You will still be able to log into your
                  account.
                </em>
              </small>
            </p> */}
          </Form>
          {/* {success && (
            <div
              className="alert alert-success"
              style={{ padding: "20px", marginTop: "25px" }}
            >
              Your profile has been updated!
            </div>
          )} */}
        </Container>
      {/* ) : (
        <Container
          className="d-flex flex-column align-items-center justify-content-center text-center"
          style={{ minHeight: "65vh" }}
        >
          <h4>
            No profile found yet.
            <br /> Your profile is how you are found in the network.
          </h4>
          <br />
          <Link to="/create-profile">
            <h3>
              <strong>Let's create create one!</strong>
            </h3>
          </Link>
        </Container> */}
      
    </Container>
  );
}
