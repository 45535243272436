import { Container } from "react-bootstrap";

const pagenotfound = () => {
    return(
        <>
<Container className="d-flex align-items-center justify-content-center" style={{minHeight:"84vh"}}>
<h4>
        page not found
</h4>
        
</Container>
        </>
    )
}

export default pagenotfound;