import { Link } from "react-router-dom"

export default function ProfileIcon({color}) {
  return (
    <Link to="/dashboard">
<svg viewBox="0 0 100 100">

  <circle cx="50.1" cy="50.1" r="49.4" fill={color}/>
  <g  fill="#fff">

  <circle cx="50.2" cy="31.5" r="22.3" />
  <path d="M50.3 58.4c-17.9 0-32.5 12-34.3 27.4a49.4 49.4 0 0 0 68.6-.4c-2-15.2-16.6-27-34.3-27Z"/>
  </g>
</svg>
    </Link>
  )
}
